import { LogoLoading } from '@amway/react-components';
import ReactDataGrid from '@inovua/reactdatagrid-community';
import { TypeComputedProps } from '@inovua/reactdatagrid-community/types';
import React, { MutableRefObject, Profiler, useCallback, useEffect, useMemo, useState } from 'react';
import useMacroGraph from '../../../resources/macro-graph/macro-graph-hook';
import {
  EditTotalSelectedValue,
  IMacroGraphResponse,
  initialSelectedCells,
  Line,
} from '../../../resources/macro-graph/macro-graph-types';
import macroGraphService from '../../../resources/macro-graph/macro-graph.service';
import './index.scss';
import {
  formatMonthNumber,
  formatRowIdToYearMonth,
  FormattedDataType,
  FormattedRow,
  getInitialFormData,
  headers,
  monthsColumns,
  parseNumberFromString,
} from './utils';

interface Props {
  data: IMacroGraphResponse;
  lines: Line[];
  rowNumber: number;
  setSelectedTotalSum: (selectedTotalSum: number) => void;
  browserByCorpCodeSelected?: string;
}

const EditableYearMonthTable: React.FC<Props> = ({
  data,
  rowNumber,
  setSelectedTotalSum,
  lines,
  browserByCorpCodeSelected,
}) => {
  const [doubleCheckSelectedCell, setDoubleCheckSelectedCell] = useState<string>('initial'); // To keep track of the cell that was double clicked
  const [cellSelectionCount, setCellSelectionCount] = useState<number>(0); // To keep track of the cell that was double clicked
  const [rawDataSource, setRawDataSource] = useState<Line[]>([]);
  const { chartRealTimeData, updateRealTimeChartData, chartStyle, setCombinedSelectedCells, combinedSelectedCells } =
    useMacroGraph();
  const [gridRef, setGridRef] = useState<MutableRefObject<TypeComputedProps | null> | null>(null);
  const [editedCells, setEditedCells] = useState<string[]>([]);
  const [loadingNewValues, setLoadingNewValues] = useState<boolean>(false);
  const [selectedCells, setSelectedCells] = useState<Record<string, boolean>>({});
  // this will be used to stop the re-rendering of unnecessary tables
  const [tableHasChanged, setTableHasChanged] = useState<boolean>(false);

  useEffect(() => {
    if (JSON.stringify(combinedSelectedCells[rowNumber]) !== JSON.stringify(selectedCells)) {
      setSelectedCells(combinedSelectedCells[rowNumber] ?? {});
      setTableHasChanged(true);
    } else {
      setTableHasChanged(false);
    }
  }, [combinedSelectedCells[rowNumber]]);

  const isFiscal = useMemo(() => chartStyle.calendars === 'fiscal', [chartStyle.calendars]);
  const monthsArray = useMemo(() => (isFiscal ? monthsColumns.fiscal : monthsColumns.default), [isFiscal]);
  const memoizedSelectedCells = useMemo(() => selectedCells, [selectedCells]);
  const realTimeDataRows = useMemo(() => chartRealTimeData?.graphs.flatMap(graph => graph.lines), [chartRealTimeData]);

  const isComparison = useMemo(
    () => rawDataSource?.[rowNumber]?.title.includes('Comparison'),
    [rawDataSource, rowNumber],
  );
  const is12MonthRollingAvg = useMemo(
    () => chartStyle.lineConfigurations[rowNumber].is12MonthRollingAvg,
    [chartStyle.lineConfigurations, rowNumber],
  );

  useEffect(() => {
    setRawDataSource(lines);
    setEditedCells(lines?.[rowNumber]?.yearMonthDraft?.map(val => String(val)) ?? []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [browserByCorpCodeSelected, chartStyle.calendars]);

  const lockedCells = useMemo(() => {
    if (is12MonthRollingAvg || isComparison) {
      return rawDataSource?.[rowNumber]?.rows?.map(row => {
        return String(row.yearMonth);
      });
    }

    return (
      rawDataSource?.[rowNumber]?.rows?.flatMap(row => {
        return row.isLocked ? String(row.yearMonth) : [];
      }) ?? []
    );
  }, [is12MonthRollingAvg, isComparison, rawDataSource, rowNumber]);

  const lockUnlockValuesOnClick = (lock: boolean, yearMonthsToBeEdited: string[]) => {
    console.log('lock/unlock');

    void macroGraphService.lockUnlockValues({
      countryCode: chartStyle.lineConfigurations[rowNumber].countryCodes[0],
      dataType: chartStyle.lineConfigurations[rowNumber].dataType,
      yearMonths: yearMonthsToBeEdited.map(yearMonth => Number(yearMonth)),
      lock,
    });

    const newRows = rawDataSource?.[rowNumber].rows.map(row => {
      if (yearMonthsToBeEdited.includes(String(row.yearMonth))) {
        row.isLocked = lock;
      }
      return row;
    });

    setRawDataSource(prev => {
      const newData = [...prev];
      newData[rowNumber].rows = newRows;
      return newData;
    });
  };

  // Used to render the floating menu when right click cell
  const renderRowContextMenu = useCallback(
    (menuProps: any, { rowProps, cellProps }: any) => {
      const year = cellProps.data.year;
      const column = cellProps.id;

      const tableRawData = data.graphs.find(graph => graph.lines[0].title === rawDataSource[rowNumber].title);

      const isTableEditable = is12MonthRollingAvg || isComparison ? false : tableRawData?.editable;

      if (column === 'year' || column === 'total' || column === 'percentage' || !isTableEditable) {
        return;
      }

      const yearMonth = `${year}${formatMonthNumber(monthsArray.findIndex(monthName => monthName === column) + 1)}`;

      let yearMonthsToBeEdited: string[] = [yearMonth];
      if (Object.entries(memoizedSelectedCells).length > 1) {
        yearMonthsToBeEdited = Object.keys(memoizedSelectedCells)
          .filter(cell => memoizedSelectedCells[cell])
          .map(cell => formatRowIdToYearMonth(cell, isFiscal));
      }

      // check if all selected cells are locked or unlocked
      const allSelectedCellsLocked = yearMonthsToBeEdited.every(yearMonth => lockedCells.includes(yearMonth));
      const allSelectedCellsUnlocked = yearMonthsToBeEdited.every(yearMonth => !lockedCells.includes(yearMonth));

      if (!allSelectedCellsLocked && !allSelectedCellsUnlocked) {
        menuProps.alignPositions = ['bl-tl'];
        menuProps.autoDismiss = true;
        menuProps.items = [
          {
            label: 'Lock all',
            onClick: () => lockUnlockValuesOnClick(true, yearMonthsToBeEdited),
          },
          {
            label: 'Unlock all',
            onClick: () => lockUnlockValuesOnClick(false, yearMonthsToBeEdited),
          },
        ];
      } else {
        menuProps.alignPositions = ['bl-tl'];
        menuProps.autoDismiss = true;
        menuProps.items = [
          {
            label: lockedCells.includes(yearMonth) ? 'Unlock' : 'Lock',
            onClick: () => lockUnlockValuesOnClick(!allSelectedCellsLocked, yearMonthsToBeEdited),
          },
        ];
      }
    },
    [
      chartStyle.lineConfigurations,
      data.graphs,
      is12MonthRollingAvg,
      isComparison,
      isFiscal,
      lockedCells,
      monthsArray,
      rawDataSource,
      rowNumber,
      memoizedSelectedCells,
    ],
  );

  //* INITIAL DATA FORMATTING
  const formatedData = useMemo<FormattedDataType>(() => {
    const tablesResult = getInitialFormData(
      realTimeDataRows ? realTimeDataRows : rawDataSource,
      rowNumber,
      chartStyle,
      isFiscal,
    );

    if (isFiscal) {
      tablesResult.map(table =>
        table.rows.forEach((row, rowIndex) => {
          if (rowIndex !== 0) {
            const currentYear = Number(row.year);
            const previousYear = currentYear - 1;
            row.year = `${previousYear % 100}/${currentYear % 100}`;
          }
        }),
      );
    }
    return tablesResult;
  }, [chartStyle.graphOptions.scaleResults, chartStyle?.lineConfigurations, rawDataSource, rowNumber, isFiscal]);

  const memoizedColumns = useMemo(() => {
    return headers(
      editedCells,
      lockedCells,
      loadingNewValues,
      chartStyle.lineColors[rowNumber]?.color ?? 'var(--primary)',
      isFiscal,
    );
  }, [editedCells, lockedCells, loadingNewValues, chartStyle.lineColors, rowNumber, isFiscal]);

  //* EDITING RAW VALUES (NOT total OR percentage)
  const editRawValues = useCallback(
    async (
      code: number,
      formattedDataEntry:
        | {
            title: string;
            rows: FormattedRow[];
          }
        | undefined,
      rowIndex: number,
      rowToBeEdited: FormattedRow | undefined,
      predYrMoCell: string,
      oldValue: string,
    ) => {
      const allYearValues = Object.entries(formattedDataEntry?.rows?.[rowIndex] ?? []).flatMap((entry, i) => {
        const [month, value] = entry;
        if (month === 'id' || month === 'year' || month === 'total' || month === 'percentage') {
          return [];
        }
        const adjustedValue = parseNumberFromString(value.toString());
        return {
          yearMonth: Number(
            `${formattedDataEntry?.rows[rowIndex].year}${formatMonthNumber(
              monthsColumns.default.findIndex(monthName => monthName === month) + 1,
            )}`,
          ),
          value: chartStyle.graphOptions.scaleResults ? adjustedValue * 1000 : adjustedValue,
          isLocked: false,
        };
      });

      const totalValueLastYear = parseNumberFromString((formattedDataEntry?.rows[rowIndex - 1]?.total ?? 0).toString());

      if (rowToBeEdited) {
        setLoadingNewValues(true);
        rowToBeEdited['total'] = 'Loading...';
        rowToBeEdited['percentage'] = 'Loading...';
      }

      const nonOldValue = oldValue === null || oldValue === undefined || oldValue === '' || oldValue === '0';
      const newValue = allYearValues.find(yearValue => yearValue.yearMonth === Number(predYrMoCell))?.value;

      const newTotalValues = await macroGraphService.editRawValues({
        countryCode: chartStyle.lineConfigurations[rowNumber].countryCodes[0],
        action: nonOldValue ? 'CREATED' : newValue === 0 ? 'DELETED' : 'UPDATED',
        dataType: chartStyle.lineConfigurations[rowNumber].dataType,
        editedValue: Number(predYrMoCell),
        allYearValues,
        lastYearTotalValue: chartStyle.graphOptions.scaleResults ? totalValueLastYear * 1000 : totalValueLastYear,
        scaled: chartStyle.graphOptions.scaleResults,
        currencyOptions: { ...chartStyle.lineConfigurations[rowNumber].currencyOptions },
      });

      if (rowToBeEdited) {
        rowToBeEdited['total'] = newTotalValues.value.toLocaleString();

        const valueToShow = `${Number((newTotalValues.percentage! * 100).toFixed(2))}%`;
        rowToBeEdited['percentage'] =
          typeof newTotalValues.percentage === 'number'
            ? newTotalValues.percentage < 0
              ? valueToShow
              : `+ ${valueToShow}`
            : '-';
      }
    },
    [chartStyle.lineConfigurations, monthsArray, rowNumber, chartStyle.graphOptions.scaleResults],
  );

  const editTotal = useCallback(
    async (
      predYrMoToBeEdited: string[],
      code: number,
      formattedDataEntry: { title: string; rows: FormattedRow[] },
      rowIndex: number,
      columnId: string,
    ) => {
      setLoadingNewValues(true);
      const copyOfTableValues = structuredClone(formattedDataEntry);
      // set loading to true
      predYrMoToBeEdited.forEach(yearMonth => {
        const monthIndex = yearMonth.slice(4, 6);
        const columnToBeEdited = monthsArray[parseInt(monthIndex, 10) - 1];
        const rowToBeEdited = formattedDataEntry.rows.find(row => row.id === `${rowNumber}-${yearMonth.slice(0, 4)}`);
        if (!rowToBeEdited) {
          return;
        }
        // @ts-ignore
        rowToBeEdited[columnToBeEdited] = 'Loading...';
      });
      // total or percentage
      const rowToBeEdited = formattedDataEntry?.rows[rowIndex];
      if (!rowToBeEdited) {
        return;
      }

      // Get values from the table to create the payload
      const year = copyOfTableValues?.rows[rowIndex]?.year;
      const allYearValues = Object.entries(copyOfTableValues?.rows?.[rowIndex] ?? []).flatMap((entry, i) => {
        const [month, value] = entry;
        if (month === 'id' || month === 'year' || month === 'total' || month === 'percentage') {
          return [];
        }
        const adjustedValue = parseNumberFromString(value.toString());
        return {
          yearMonth: Number(`${year}${formatMonthNumber(monthsArray.findIndex(monthName => monthName === month) + 1)}`),
          value: chartStyle.graphOptions.scaleResults ? adjustedValue * 1000 : adjustedValue,
          isLocked: false,
        };
      });
      const totalValue =
        columnId === 'total'
          ? parseNumberFromString((copyOfTableValues?.rows[rowIndex]?.total ?? 0).toString())
          : undefined;
      const percentage =
        columnId === 'percentage'
          ? parseFloat((copyOfTableValues?.rows[rowIndex]?.percentage ?? 0).toString().replaceAll('%', '')) / 100
          : undefined;
      const totalValueLastYear = parseNumberFromString((copyOfTableValues?.rows[rowIndex - 1]?.total ?? 0).toString());

      const selectedValues: EditTotalSelectedValue[] = predYrMoToBeEdited.map(yearMonth => {
        return { yearMonth: Number(yearMonth), action: 'UPDATED' };
      });

      const newTotalValues = await macroGraphService.editTotalValue({
        countryCode: chartStyle.lineConfigurations[rowNumber].countryCodes[0],
        dataType: chartStyle.lineConfigurations[rowNumber].dataType,
        newValue: {
          value:
            totalValue !== undefined
              ? chartStyle.graphOptions.scaleResults
                ? totalValue * 1000
                : totalValue
              : undefined,
          percentage,
        },
        selectedValues: selectedValues,
        allYearValues,
        lastYearTotalValue: chartStyle.graphOptions.scaleResults ? totalValueLastYear * 1000 : totalValueLastYear,
        scaled: chartStyle.graphOptions.scaleResults,
        currencyOptions: { ...chartStyle.lineConfigurations[rowNumber].currencyOptions },
      });

      // Set results to the table
      rowToBeEdited[columnId as keyof FormattedRow] = newTotalValues.newTotal.value.toLocaleString();
      rowToBeEdited['percentage'] =
        typeof newTotalValues.newTotal.percentage === 'number'
          ? `${(newTotalValues.newTotal.percentage * 100).toFixed(2)}%`
          : '-';

      newTotalValues.newSelectedValues.forEach(yearMonth => {
        const monthIndex = String(yearMonth.yearMonth).slice(4, 6);
        const columnToBeEdited = monthsArray[parseInt(monthIndex, 10) - 1];
        const rowToBeEdited = formattedDataEntry.rows.find(
          row => row.id === `${rowNumber}-${String(yearMonth.yearMonth).slice(0, 4)}`,
        );
        if (!rowToBeEdited) {
          return;
        }
        // @ts-ignore
        rowToBeEdited[columnToBeEdited] = yearMonth.value.toFixed(0);
      });
      setEditedCells(prev => [...prev, ...predYrMoToBeEdited]);
    },
    [chartStyle.lineConfigurations, monthsArray, rowNumber, chartStyle.graphOptions.scaleResults],
  );

  // Function to handle key down event
  const handleKeyDown = useCallback(
    event => {
      if (event.key === 'Enter' && gridRef && gridRef.current) {
        if (
          memoizedSelectedCells &&
          Object.keys(memoizedSelectedCells).length === 1 &&
          doubleCheckSelectedCell === Object.keys(memoizedSelectedCells)[0]
        ) {
          const [rowId, columnId] = Object.entries(memoizedSelectedCells)?.[0][0].split(','); // Assuming single selection
          const rowIndex = formatedData[rowNumber].rows.findIndex(row => row.id === rowId);
          if (gridRef.current.startEdit) {
            gridRef.current.startEdit({ rowIndex, columnId });
          }
        }
      }
    },
    [doubleCheckSelectedCell, formatedData, gridRef, rowNumber, memoizedSelectedCells],
  );

  useEffect(() => {
    if (Object.keys(memoizedSelectedCells).length === 1 && cellSelectionCount > 1) {
      setDoubleCheckSelectedCell(Object.keys(memoizedSelectedCells)[0]);
    } else {
      setCellSelectionCount(prev => prev + 1);
    }
  }, [memoizedSelectedCells]);

  // Attach the event listener once the grid is ready
  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  const onEditComplete = useCallback(
    async ({ value, columnId, rowIndex }) => {
      const finalValue = value === '' || !value ? '0' : value;
      const rowId = formatedData[rowNumber].rows[rowIndex].id;
      const formattedDataEntry = formatedData?.[rowNumber];
      if (!formattedDataEntry) {
        return;
      }
      const rowToBeEdited = formattedDataEntry.rows.find(row => row.id === rowId);
      if (!rowToBeEdited) {
        return;
      }

      // return if new value is the same as the old value
      if (rowToBeEdited[columnId as keyof FormattedRow] === finalValue) {
        return;
      }

      const predYrMoCell = `${formattedDataEntry.rows[rowIndex].year}${formatMonthNumber(
        monthsColumns.default.findIndex(month => month === columnId) + 1,
      )}`;

      if (columnId !== 'year' && columnId !== 'total' && columnId !== 'percentage') {
        const oldValue = rowToBeEdited[columnId as keyof FormattedRow].toString();
        rowToBeEdited[columnId as keyof FormattedRow] = finalValue;
        await editRawValues(0, formattedDataEntry, rowIndex, rowToBeEdited, predYrMoCell, oldValue);
        setEditedCells(prev => [...prev, predYrMoCell]);
      } else if (columnId === 'total' || columnId === 'percentage') {
        rowToBeEdited[columnId as keyof FormattedRow] = finalValue;

        const cellsToBeEdited = Object.entries(memoizedSelectedCells).flatMap(([cell, isSelected]) => {
          if (isSelected) {
            const [rowId, columnId] = cell.split(',');
            if (columnId === 'year') {
              return [];
            }

            return `${formattedDataEntry?.rows.find(row => row.id === rowId)?.year}${formatMonthNumber(
              monthsArray.findIndex(monthName => monthName === columnId) + 1,
            )}`;
          }
          return [];
        });

        await editTotal(cellsToBeEdited, 0, formattedDataEntry, rowIndex, columnId);
      }

      setCombinedSelectedCells(initialSelectedCells);
      setCellSelectionCount(2);

      setTimeout(() => {
        setLoadingNewValues(false);
      }, 1000);
      void updateRealTimeChartData();
    },
    [
      formatedData,
      memoizedSelectedCells,
      rowNumber,
      setCombinedSelectedCells,
      updateRealTimeChartData,
      editRawValues,
      editTotal,
    ],
  );

  useEffect(() => {
    const selected = Object.keys(memoizedSelectedCells).filter(cell => memoizedSelectedCells[cell]);

    if (selected.length === 0) {
      setSelectedTotalSum(0);
      return;
    }

    const [rowId, columnId] = selected?.[0]?.split(',');
    const [rowIdNumber] = rowId?.split('-');

    if (columnId === 'year') {
      return;
    }

    if (!rowIdNumber || Number(rowIdNumber) !== rowNumber) {
      return;
    }

    const totalSum = selected.reduce((acc, cell) => {
      const [rowId, columnId] = cell.split(',');
      const [rowIdNumber] = rowId.split('-');

      if (columnId === 'year') {
        return acc;
      }

      if (Number(rowIdNumber) !== rowNumber) {
        return acc;
      }

      const formattedDataEntry = formatedData?.[rowNumber];
      if (!formattedDataEntry) {
        return acc;
      }

      return (
        acc +
        parseNumberFromString(
          // @ts-ignore
          formattedDataEntry.rows.find(row => row.id === rowId)?.[columnId]?.replaceAll('-', '0'),
        )
      );
    }, 0);

    setSelectedTotalSum(totalSum);
  }, [formatedData, memoizedSelectedCells]);

  const onCellSelectionChange = useCallback(
    (newSelectedCells: Record<string, boolean>) => {
      const newCellsColumn = Object.keys(newSelectedCells).flatMap(cell =>
        !memoizedSelectedCells[cell] ? [cell.split(',')[1]] : [],
      );

      if (newCellsColumn.length === 0) {
        setCombinedSelectedCells({ ...initialSelectedCells });
        return;
      }

      const [rowId] = Object.keys(newSelectedCells)[0]?.split(',');

      if (!rowId) {
        return;
      } else if (
        (newCellsColumn.includes('year') ||
          newCellsColumn.includes('total') ||
          newCellsColumn.includes('percentage')) &&
        Object.keys(combinedSelectedCells[rowNumber]).length > 1 &&
        Object.keys(combinedSelectedCells[rowNumber])
          .map(cell => cell.split(',')[0])
          .includes(rowId)
      ) {
        // unselect rows that are not the same as rowId
        const newSelectedCellsObj = Object.entries(combinedSelectedCells[rowNumber]).reduce(
          (acc, [cell, isSelected]) => {
            const [cellRowId] = cell.split(',');
            if (cellRowId === rowId) {
              acc[cell] = isSelected;
            }
            if (lockedCells.includes(formatRowIdToYearMonth(cell, isFiscal))) {
              acc[cell] = false;
            }
            return acc;
          },
          {} as Record<string, boolean>,
        );
        setCombinedSelectedCells({ ...initialSelectedCells, [rowNumber]: newSelectedCellsObj });

        return;
      } else if (
        (newCellsColumn.includes('year') ||
          newCellsColumn.includes('total') ||
          newCellsColumn.includes('percentage')) &&
        newCellsColumn.length === 1
      ) {
        // select all values for that year

        const formattedDataEntry = formatedData?.[rowNumber];
        if (!formattedDataEntry) {
          return;
        }
        const newSelectedCellsObj = monthsArray.reduce((acc, cell) => {
          acc[`${rowId},${cell}`] = true;
          return acc;
        }, {} as Record<string, boolean>);
        setCombinedSelectedCells({ ...initialSelectedCells, [rowNumber]: newSelectedCellsObj });
        return;
      } else if (
        (newCellsColumn.includes('total') || newCellsColumn.includes('percentage')) &&
        newCellsColumn.length > 1
      ) {
        return;
      } else {
        setCombinedSelectedCells({ ...initialSelectedCells, [rowNumber]: newSelectedCells });
      }
    },
    [memoizedSelectedCells, formatedData, rowNumber, monthsArray, setCombinedSelectedCells, lockedCells],
  );

  const onEditStart = useCallback(
    ({ rowIndex, columnId }) => {
      const formattedDataEntry = formatedData?.[rowNumber];
      if (!formattedDataEntry) {
        return;
      }
    },
    [formatedData, rowNumber],
  );

  return formatedData !== null && formatedData !== undefined && formatedData.length > 0 ? (
    <DataGridMemo
      formatedData={formatedData}
      rowNumber={rowNumber}
      renderRowContextMenu={renderRowContextMenu}
      memoizedSelectedCells={memoizedSelectedCells}
      onCellSelectionChange={onCellSelectionChange}
      memoizedColumns={memoizedColumns}
      setGridRef={setGridRef}
      onEditStart={onEditStart}
      onEditComplete={onEditComplete}
      tableHasChanged={tableHasChanged}
      scaleResults={chartStyle.graphOptions.scaleResults}
      browserByCorpCodeSelected={browserByCorpCodeSelected}
    />
  ) : rowNumber === 0 ? (
    <LogoLoading />
  ) : (
    <></>
  );
};

const DataGridMemo = React.memo(
  ({
    formatedData,
    rowNumber,
    renderRowContextMenu,
    memoizedSelectedCells,
    onCellSelectionChange,
    memoizedColumns,
    setGridRef,
    onEditStart,
    onEditComplete,
    scaleResults,
  }: {
    formatedData: FormattedDataType;
    rowNumber: number;
    renderRowContextMenu: any;
    memoizedSelectedCells: Record<string, boolean>;
    onCellSelectionChange: (newSelectedCells: Record<string, boolean>) => void;
    memoizedColumns: any;
    setGridRef: (ref: MutableRefObject<TypeComputedProps | null>) => void;
    onEditStart: (params: { rowIndex: number; columnId: string }) => void;
    onEditComplete: (params: { value?: string; columnId: string; rowIndex: number }) => void;
    tableHasChanged: boolean;
    scaleResults: boolean;
    browserByCorpCodeSelected: string | undefined;
  }) => {
    return (
      <Profiler
        id={`table-${rowNumber}`}
        onRender={(id, phase, actualDuration) => console.log(id, phase, actualDuration)}>
        {scaleResults && rowNumber == 0 ? (
          <div className="y-legend-container-table">Tables values scaled (000)</div>
        ) : (
          <></>
        )}
        <div className="table-container">
          <ReactDataGrid
            onReady={setGridRef}
            idProperty="id"
            style={{
              maxWidth: '100%',
              minHeight:
                rowNumber === 0
                  ? formatedData?.[rowNumber]?.rows.length * 40 + 44
                  : formatedData?.[rowNumber]?.rows.length * 40 + 2,
            }}
            showColumnMenuLockOptions={false}
            showColumnMenuTool
            renderRowContextMenu={renderRowContextMenu}
            cellSelection={memoizedSelectedCells}
            onCellSelectionChange={onCellSelectionChange}
            columns={memoizedColumns}
            dataSource={formatedData?.[rowNumber]?.rows}
            onEditStart={onEditStart}
            onEditComplete={onEditComplete}
            editable
            showHeader={rowNumber === 0}
          />
        </div>
      </Profiler>
    );
  },
  (prevProps, nextProps) => {
    const scaleResultsChanged = prevProps.scaleResults !== nextProps.scaleResults;
    const browseByCorpCodeChanged = prevProps.browserByCorpCodeSelected !== nextProps.browserByCorpCodeSelected;
    // check if this table need to update selected cells
    if (nextProps.tableHasChanged || scaleResultsChanged || browseByCorpCodeChanged) {
      return false; // re-render because selected cells have changed
    }
    return true; // don't re-render
  },
);

export default React.memo(EditableYearMonthTable);
