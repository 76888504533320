import SideWrapper from '../../../../../components/ui/side-wrapper';
import { defaultNote } from '../../../../../resources/notes/utils';
import NoteForm from '../note-form';

interface Props {
  isToShow: boolean;
  close: () => void;
}

const AddNoteTab: React.FC<Props> = ({ isToShow, close }) => {
  return (
    <SideWrapper title="Add note" isToShow={isToShow} close={close} style={{ position: 'absolute', top: '-3px' }}>
      <NoteForm isToAdd={true} defaultValue={defaultNote} close={close} />
    </SideWrapper>
  );
};

export default AddNoteTab;
