import moment from 'moment';
import React, { useCallback, useMemo } from 'react';
import {
  Area,
  Bar,
  CartesianGrid,
  ComposedChart,
  Legend,
  Line,
  ReferenceArea,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { monthsColumns } from '../../../components/core/editable-yrMo-table/utils';
import useMacroGraph from '../../../resources/macro-graph/macro-graph-hook';
import { Line as LineMacro } from '../../../resources/macro-graph/macro-graph-types';
import { colorOptions, getYearTitleBased } from '../utils';
import './index.scss';
import { RenderTooltipContent } from './tooltip';
import { formatXAxisTrend, formatYAxis, getYAxisLabel, toPercent } from './utils';

interface Props {
  macroGraph: LineMacro[];
  legends?: boolean;
}

export interface IUnifiedDataset {
  yearMonth: number | string;
  [country: string]: number | string;
}

const MacroGraphChart: React.FC<Props> = ({ macroGraph, legends = false }) => {
  const { chartRealTimeData, chartStyle, graphTitle, graphSubtitle, combinedSelectedCells } = useMacroGraph();

  const selectedCells = useMemo(() => {
    const populatedTable = Object.values(combinedSelectedCells).find(table => Object.keys(table).length > 0);
    if (populatedTable) return populatedTable;
    return {};
  }, [combinedSelectedCells]);

  const yAxisLabelLeft = useMemo(() => {
    return getYAxisLabel(chartStyle.lineConfigurations, 'left');
  }, [chartStyle.lineConfigurations]);

  const yAxisLabelRight = useMemo(() => {
    return getYAxisLabel(chartStyle.lineConfigurations, 'right');
  }, [chartStyle.lineConfigurations]);

  const isFiscal = useMemo(
    () => chartStyle.calendars === 'fiscal' || chartStyle.calendars === 'line_date',
    [chartStyle.calendars],
  );
  const isSeasonal = useMemo(() => chartStyle.styles === 'seasonal', [chartStyle.styles]);

  const highlightedValues = useMemo(() => {
    if (selectedCells)
      return Object.keys(selectedCells).map(key => {
        const [yearPrefix, month] = key.split(',');
        const year = yearPrefix.split('-')[1];
        const monthNum = moment(month, 'MMM').format('MM');
        const yearMonth = Number(Number(year) + monthNum);
        if (isFiscal && yearMonth % 100 >= 9) {
          return yearMonth - 100;
        }
        return yearMonth;
      });
    else return [];
  }, [selectedCells, isFiscal, isSeasonal]);

  const realTimeDataRows = chartRealTimeData?.graphs.flatMap(graph => graph.lines);

  const yearTitleOptions = useMemo(() => {
    const years: number[] = [];
    macroGraph.forEach(obj => {
      obj.rows.forEach(row => {
        const year = Math.floor(row.yearMonth / 100);
        if (!years.includes(year)) years.push(year);
      });
    });

    return years.map(year => getYearTitleBased(year.toString()));
  }, [macroGraph]);

  const putValueInDataset = (result: IUnifiedDataset[], title: string, value: number, yearMonth: string | number) => {
    const existingRow = result.find(r => r.yearMonth === yearMonth);
    if (existingRow) {
      existingRow[title] = Number(value.toFixed(2));
    } else {
      const newRow: IUnifiedDataset = { yearMonth: yearMonth };
      newRow[title] = Number(value.toFixed(2));
      result.push(newRow);
    }
  };

  const unifiedDataset = useMemo(() => {
    const result: IUnifiedDataset[] = [];
    const mostUpdatedData = realTimeDataRows ? realTimeDataRows : macroGraph;

    if (isSeasonal) {
      mostUpdatedData[0].rows.forEach(row => {
        const title = getYearTitleBased(row.yearMonth.toString());
        const month = moment()
          .month((row.yearMonth % 100) - 1)
          .format('MMM');

        putValueInDataset(result, title, row.value, month);
      });

      const monthOrder = isFiscal ? monthsColumns.fiscal : monthsColumns.default;
      result.sort(
        (a, b) =>
          monthOrder.indexOf(a.yearMonth.toString().toLowerCase()) -
          monthOrder.indexOf(b.yearMonth.toString().toLowerCase()),
      );
    } else {
      mostUpdatedData.forEach((prediction, index) => {
        prediction.rows.forEach(row => {
          putValueInDataset(result, `${prediction.title}-${index}`, row.value, row.yearMonth);
        });
      });
    }

    return result;
  }, [macroGraph, realTimeDataRows, isFiscal, isSeasonal]);

  const linePercentageConfig = chartStyle.lineConfigurations.filter(
    config => config.graphAs === 'percentage-area' || config.graphAs === 'percentage-bar',
  );

  const isArea = linePercentageConfig.length > 0 && linePercentageConfig[0].graphAs === 'percentage-area';
  const isBar = linePercentageConfig.length > 0 && linePercentageConfig[0].graphAs === 'percentage-bar';
  const isAnimationActive = useMemo(() => macroGraph.length < 5, [macroGraph.length]);

  const renderGraph = useCallback(
    (title: string, index: number, seasonal: boolean) => {
      const graphOption = seasonal
        ? chartStyle.lineConfigurations[0]?.graphAs
        : chartStyle.lineConfigurations[index]?.graphAs ?? 'line';
      const fillOption = seasonal ? colorOptions[index] : chartStyle.lineColors[index]?.color ?? 'var(--primary)';

      if (graphOption === 'bar' && !(linePercentageConfig.length > 0))
        return (
          <Bar
            key={index}
            type="monotone"
            dataKey={title}
            fill={fillOption}
            barSize={chartStyle.graphOptions.thickLines ? 20 : 10}
            yAxisId={chartStyle.lineConfigurations[index]?.scale ?? 'left'}
            isAnimationActive={isAnimationActive}
          />
        );

      if (graphOption === 'stacked-bar' || graphOption === 'percentage-bar' || isBar)
        return (
          <Bar
            key={index}
            type="monotone"
            stackId="2"
            dataKey={title}
            fill={fillOption}
            barSize={chartStyle.graphOptions.thickLines ? 20 : 10}
            yAxisId={chartStyle.lineConfigurations[index]?.scale ?? 'left'}
            isAnimationActive={isAnimationActive}
          />
        );

      if (graphOption === 'area' && !(linePercentageConfig.length > 0))
        return (
          <Area
            type="monotone"
            dataKey={title}
            fill={fillOption}
            yAxisId={chartStyle.lineConfigurations[index]?.scale ?? 'left'}
            isAnimationActive={isAnimationActive}
          />
        );

      if (graphOption === 'stacked-area' || graphOption === 'percentage-area' || isArea)
        return (
          <Area
            type="monotone"
            stackId="1"
            dataKey={title}
            fill={fillOption}
            yAxisId={chartStyle.lineConfigurations[index]?.scale ?? 'left'}
            isAnimationActive={isAnimationActive}
          />
        );

      if (graphOption === 'line' && !(linePercentageConfig.length > 0))
        return (
          <Line
            key={index}
            type="monotone"
            dataKey={title}
            stroke={fillOption}
            fill={fillOption}
            strokeWidth={chartStyle.graphOptions.thickLines ? 3 : 1}
            yAxisId={chartStyle.lineConfigurations[index]?.scale ?? 'left'}
            isAnimationActive={isAnimationActive}
          />
        );

      return <></>;
    },
    [
      chartStyle.graphOptions.thickLines,
      chartStyle.lineColors,
      chartStyle.lineConfigurations,
      isAnimationActive,
      isArea,
      isBar,
      linePercentageConfig.length,
    ],
  );

  const valueToCompare = isSeasonal ? moment().format('MMM') : moment().year() * 100 + moment().month() + 1;
  const newYearStarts = unifiedDataset
    .filter(entry => entry.yearMonth.toString().endsWith(isFiscal ? '09' : '01'))
    .map(entry => entry.yearMonth);

  return (
    <div id="char-component-div" style={{ backgroundColor: 'white' }}>
      <div className="y-legend-container">
        <p className="rotated-text left">
          {yAxisLabelLeft.length > 50 ? yAxisLabelLeft.substring(0, 50) + '...' : yAxisLabelLeft}
        </p>
        <p className="rotated-text right">
          {yAxisLabelRight.length > 50 ? yAxisLabelRight.substring(0, 50) + '...' : yAxisLabelRight}
        </p>
      </div>
      <h5>{graphTitle}</h5>
      <h6>{graphSubtitle}</h6>
      <ResponsiveContainer width="100%" height={400}>
        <ComposedChart
          width={1700}
          height={400}
          data={unifiedDataset}
          style={{ width: '100%' }}
          stackOffset={linePercentageConfig.length > 0 ? 'expand' : 'none'}>
          <CartesianGrid strokeDasharray="3 3" />

          <XAxis dataKey="yearMonth" tickFormatter={isSeasonal ? (tick: string) => tick : formatXAxisTrend} />

          <YAxis
            yAxisId="left"
            orientation="left"
            tickFormatter={linePercentageConfig.length > 0 ? toPercent : formatYAxis}
          />

          <YAxis
            yAxisId="right"
            orientation="right"
            tickFormatter={linePercentageConfig.length > 0 ? toPercent : formatYAxis}
          />

          <Tooltip
            content={<RenderTooltipContent isSeasonal={isSeasonal} showTotal={linePercentageConfig.length > 0} />}
          />
          {legends ? <Legend align="left" /> : <></>}

          {highlightedValues.map((value, index) => {
            const entryIndex = unifiedDataset.findIndex(entry => entry.yearMonth === value);
            if (entryIndex !== -1) {
              return (
                <ReferenceArea
                  key={`highlight-${index}`}
                  x1={entryIndex === 0 ? value : unifiedDataset[entryIndex - 1].yearMonth}
                  x2={value}
                  strokeOpacity={0.3}
                  fill="yellow"
                  fillOpacity={0.3}
                  yAxisId={chartStyle.lineConfigurations[0]?.scale ?? 'left'}
                />
              );
            }
            return null;
          })}

          {newYearStarts.map((start, index) => (
            <ReferenceLine
              key={index}
              x={start}
              stroke="black"
              yAxisId={chartStyle.lineConfigurations[0]?.scale ?? 'left'}
            />
          ))}

          <ReferenceLine
            key="currentMonth"
            x={unifiedDataset.filter(entry => entry.yearMonth === valueToCompare).map(entry => entry.yearMonth)[0]}
            stroke="blue"
            yAxisId={chartStyle.lineConfigurations[0]?.scale ?? 'left'}
          />

          {isSeasonal
            ? yearTitleOptions.map((title, index) => renderGraph(`${title}`, index, true))
            : macroGraph.map((dataset, index) => renderGraph(`${dataset.title}-${index}`, index, false))}
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
};

export default React.memo(MacroGraphChart);
