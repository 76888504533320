import axios, { AxiosResponse } from 'axios';
import { WithAbortFn } from '../../@types/external-api';
import { Item } from '../../components/ui/dropdownBtn';
import { apiWrapper } from '../../config/api-wrapper';
import { GetDailyReportRequest } from './daily-report-types';

class DailyReportService {
  public getAllDataTypes(): WithAbortFn<Promise<Item[]>> {
    const source = axios.CancelToken.source();

    return {
      promise: apiWrapper.api.get('/daily-report/data-types').then(res => res.data),
      abort: source.cancel,
    };
  }

  public getAllBusinessLines(): WithAbortFn<Promise<Item[]>> {
    const source = axios.CancelToken.source();

    return {
      promise: apiWrapper.api.get('/daily-report/business-lines').then(res => res.data),
      abort: source.cancel,
    };
  }

  public downloadDailyReport(request: GetDailyReportRequest): WithAbortFn<Promise<AxiosResponse<any, any>>> {
    const source = axios.CancelToken.source();

    return {
      promise: apiWrapper.api.post('/daily-report/download', request, { responseType: 'blob' }),
      abort: source.cancel,
    };
  }

  public sendToEmailDailyReport(request: GetDailyReportRequest): WithAbortFn<Promise<AxiosResponse>> {
    const source = axios.CancelToken.source();

    return {
      promise: apiWrapper.api.post('/daily-report/email', request),
      abort: source.cancel,
    };
  }
}

export default new DailyReportService();
