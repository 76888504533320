import axios, { AxiosResponse } from 'axios';
import { WithAbortFn } from '../../@types/external-api';
import { Item } from '../../components/ui/dropdownBtn';
import { apiWrapper } from '../../config/api-wrapper';
import { ForecastNotApplied, GetTempleteRequest } from './import-forecast-types';

class ImportForecastService {
  public importForecast(formData: FormData): WithAbortFn<Promise<ForecastNotApplied[]>> {
    const source = axios.CancelToken.source();

    return {
      promise: apiWrapper.api
        .post('/import-forecast/upload', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(res => res.data),
      abort: source.cancel,
    };
  }

  public getAllDataTypes(): WithAbortFn<Promise<Item[]>> {
    const source = axios.CancelToken.source();

    return {
      promise: apiWrapper.api.get('/import-forecast/authorized-data-types').then(res => res.data),
      abort: source.cancel,
    };
  }

  public downloadTemplate(req: GetTempleteRequest): WithAbortFn<Promise<AxiosResponse<any, any>>> {
    const source = axios.CancelToken.source();

    return {
      promise: apiWrapper.api.post('/import-forecast/download-template', req, { responseType: 'blob' }),
      abort: source.cancel,
    };
  }
}

export default new ImportForecastService();
