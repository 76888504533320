import { Button, Typography } from '@amway/react-components';
import ReactDataGrid from '@inovua/reactdatagrid-community';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Row } from 'react-bootstrap';
import { NormalAlert } from '../../../components/core/alert-card/alert-cards';
import { CurrencyAdminTableResponse, EditedValue } from '../../../resources/currency-admin/currency-admin-types';
import currencyAdminService from '../../../resources/currency-admin/currency-admin.service';
import { corpReferHeaders, FormatedRow, formatRows } from '../utils';

interface Props {
  data: CurrencyAdminTableResponse;
  onResetData: () => void;
}

export default function CurrencyAdminTable({ data, onResetData }: Props) {
  const gridInstance = React.useRef<any>(null);
  const gridInvertedInstance = React.useRef<any>(null);

  const [editedValues, setEditedValues] = useState<EditedValue[]>([]);
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const [formattedData, setFormattedData] = useState<FormatedRow[]>(formatRows(data.rows));
  const [formattedInvertedData, setFormattedInvertedData] = useState<FormatedRow[]>(formatRows(data.rowsInverted));

  useEffect(() => {
    setFormattedData(formatRows(data.rows));
    setFormattedInvertedData(formatRows(data.rowsInverted));
  }, [data]);

  const minHeight = useMemo(() => {
    return (Math.min(Number(data.rows.length), formattedData.length) + 1) * 40 + 4;
  }, [formattedData]);

  const onEditStart = useCallback(
    ({ columnId, rowIndex }, isInverted) => {
      if (!data.isEditable) {
        if (isInverted) gridInvertedInstance.current.cancelEdit({ columnId, rowIndex });
        else gridInstance.current.cancelEdit({ columnId, rowIndex });
        return false;
      }
    },
    [data],
  );

  const onEditComplete = useCallback(
    async ({ value, columnId, rowIndex }, isInverted) => {
      if (isInverted) {
        if (value == formattedInvertedData[rowIndex][columnId]) return;
      } else {
        if (value == formattedData[rowIndex][columnId]) return;
      }

      const corpCd = formattedData[rowIndex].corp;

      const finalValue = Number(value);
      const invertedValue = finalValue != 0 ? 1 / finalValue : 0;

      const editedValue = {
        countryCode: corpCd,
        exchangeRateType: data.exchangeRateType,
        yearMonth: Number(columnId),
        newValue: isInverted ? invertedValue : finalValue,
      };

      setEditedValues(prev => [...prev, editedValue]);

      formattedData[rowIndex][columnId] = isInverted ? invertedValue : finalValue;
      setFormattedData(formattedData);
      formattedInvertedData[rowIndex][columnId] = isInverted ? finalValue : invertedValue;
      setFormattedInvertedData(formattedInvertedData);
    },

    [formattedData, formattedInvertedData],
  );

  const onUpdateValues = useCallback(async () => {
    setIsUpdating(true);
    try {
      await currencyAdminService.editValues(editedValues);
      NormalAlert('Exchange rate edits saved successfully');
    } catch {
      NormalAlert('Error when saving exchange rate edits');
    }
    setEditedValues([]);
    setIsUpdating(false);
  }, [editedValues]);

  return (
    <>
      <Row>
        <div style={{ display: 'flex', width: '100%', justifyContent: 'end', gap: 15, marginBottom: 25 }}>
          <Button
            disabled={isUpdating || editedValues.length < 1}
            loading={isUpdating}
            backgroundColor="warning-success"
            onClick={onUpdateValues}>
            Update
          </Button>
          <Button variant="link" fontColor="warning-error" onClick={onResetData}>
            Discart changes
          </Button>
        </div>
      </Row>
      <Row>
        <Typography variant="subheading">Local to USD</Typography>
        <Row>
          <ReactDataGrid
            idProperty="id"
            style={{ height: '100%', minHeight: `${minHeight}px` }}
            reorderColumns={false}
            dataSource={formattedData}
            columns={corpReferHeaders(editedValues, data.columns)}
            editable={data.isEditable}
            onEditStart={param => {
              onEditStart(param, false);
            }}
            onEditComplete={param => {
              onEditComplete(param, false);
            }}
            showColumnMenuLockOptions={false}
            showColumnMenuGroupOptions={false}
            showColumnMenuFilterOptions={false}
            showGroupSummaryRow={false}
            onReady={instance => {
              gridInstance.current = instance;
            }}
          />
        </Row>
      </Row>
      <Row>
        <Typography variant="subheading">USD to Local</Typography>
        <Row>
          <ReactDataGrid
            idProperty="id"
            style={{ height: '100%', minHeight: `${minHeight}px` }}
            reorderColumns={false}
            dataSource={formattedInvertedData}
            columns={corpReferHeaders(editedValues, data.columns)}
            editable={data.isEditable}
            onEditStart={param => {
              onEditStart(param, true);
            }}
            onEditComplete={param => {
              onEditComplete(param, true);
            }}
            showColumnMenuLockOptions={false}
            showColumnMenuGroupOptions={false}
            showColumnMenuFilterOptions={false}
            showGroupSummaryRow={false}
            onReady={instance => {
              gridInvertedInstance.current = instance;
            }}
          />
        </Row>
      </Row>
    </>
  );
}
