import { createContext } from 'react';
import { Item } from '../../components/ui/dropdownBtn';
import { DataTypeMetadata, GroupedOption, MultiSelectCheckboxOption } from './common-types';

export interface CommonCtxType {
  dataTypes: GroupedOption[];
  businessLines: Item[];
  categories: Item[];
  fcastSources: Item[];
  countries: Item[];
  exchangeRateYearOptions: Item[];
  countriesTree: MultiSelectCheckboxOption[];
  exchangeRates: { name: string; value: string }[];
  dataTypesMetadata: DataTypeMetadata[];
  yearOptions: Item[];
  fetchAllDataTypes: () => void;
  fetchAllBusinessLines: () => void;
  fetchAllCategories: () => void;
  fetchAllCountries: () => void;
  fetchAllCountriesTree: () => void;
  fetchAllExchangeRates: () => void;
  fetchAllFcastSources: () => void;
  fetchAllExchangeRateYearOptions: () => void;
  fetchAllDataTypesMetadata: () => void;
  fetchAllYearOptions: () => void;
}

const CommonContext = createContext<CommonCtxType>(null as any);

export default CommonContext;
