import { CellProps, TypeColumn } from '@inovua/reactdatagrid-community/types';
import moment from 'moment';
import { EditedValue, MaintenanceTableRow } from '../../resources/maintenance/maintenance-types';

const renderHeader = (
  header: string,
  lockedCells: string[],
  editedValues: EditedValue[],
  isEditingValue: boolean,
): TypeColumn => {
  return {
    name: header,
    header: formatHeaderName(header),
    defaultWidth: header.length > 19 ? 270 : 170,
    editable: (_editValue, cellProps) =>
      lockedCells.includes(`${cellProps?.data.id},${cellProps?.id}`) || isEditingValue ? false : true,
    render: ({ cellProps }: { cellProps: CellProps }) => {
      const isLocked = lockedCells.includes(`${cellProps?.data.id},${cellProps?.id}`);
      const isEdited = editedValues.some(
        editedValue =>
          editedValue.yrMo === formatDate(`${cellProps.data.year}-${cellProps.data.month}`) &&
          cellProps.id === editedValue.dataType,
      );
      return (
        <div style={{ position: 'relative' }}>
          {!isLocked && (
            <div
              style={{
                position: 'absolute',
                top: '-16px',
                right: '-15px',
                zIndex: 30,
                width: '15px',
                height: '15px',
                backgroundColor: 'var(--primary)',
                rotate: '45deg',
              }}
            />
          )}
          <span
            style={{
              color: isEdited ? 'green' : 'var(--black)',
              fontWeight: isEdited ? 'bold' : 'normal',
            }}>
            {cellProps.value.toLocaleString()}
          </span>
        </div>
      );
    },
  };
};

export const corpReferHeaders = (
  lockedCells: string[],
  editedValues: EditedValue[],
  isEditingValue: boolean,
): TypeColumn[] => [
  { defaultLocked: true, name: 'id', header: 'Id', defaultVisible: false, defaultWidth: 100, type: 'number' },
  { name: 'corp', header: 'Corp', editable: false, style: { position: 'sticky' } },
  { name: 'year', header: 'Year', editable: false, style: { position: 'sticky' } },
  { name: 'month', header: 'Month', editable: false, style: { position: 'sticky' } },
  ...dataTypeColumns.map((header, index) => renderHeader(header, lockedCells, editedValues, isEditingValue)),
];

export const formatRows = (rawRows: MaintenanceTableRow[]) => {
  return rawRows.map(row => {
    return {
      id: `${row.corp},${formatDate(`${row.year}-${row.month}`)}`,
      ...row,
    };
  });
};

export const getCellId = (row: MaintenanceTableRow, columnName: string) => {
  return `${row.corp},${row.year},${row.month},${columnName}`;
};

export const formatDate = (date: string) => {
  return Number(moment(date).format('YYYYMM'));
};

const formatHeaderName = (header: string) => {
  return header.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase());
};

export const dataTypeColumns = [
  'aboApplication',
  'annualDistinctBuyerCountAbo',
  'annualDistinctBuyerCountCustomer',
  'annualDistinctBuyerCountTotal',
  'avgMonthlyBuyersAbo',
  'avgMonthlyBuyersCustomer',
  'bv',
  'distCost',
  'eaches',
  'orders',
  'productivityAboMonthly',
  'productivityCustomerMonthly',
  'pv',
  'repeatBuyerAbo',
  'repeatBuyerCustomer',
  'repeatBuyerTotal',
  'revenue',
  'sopAboSales',
  'sopCustomerSales',
  'sopTotalSales',
  'totalApplication',
];
