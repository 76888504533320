import axios from 'axios';
import { WithAbortFn } from '../../@types/external-api';
import { apiWrapper } from '../../config/api-wrapper';
import { CountryCodeAdminTree, SaveCountryCodeRelationshipRequest } from './country-code-admin-types';

class CountryCodeAdminService {
  public fetchCountryCodeAdminTree(): WithAbortFn<Promise<CountryCodeAdminTree>> {
    const source = axios.CancelToken.source();

    return {
      promise: apiWrapper.api.get('/country-code-admin/get').then(res => res.data),
      abort: source.cancel,
    };
  }

  public saveCountryCodeRelationships(req: SaveCountryCodeRelationshipRequest) {
    return apiWrapper.api.post('/country-code-admin/save', req);
  }
}

export default new CountryCodeAdminService();
