import { useMemo } from 'react';
import { ForecastChangesHistoryResponse } from '../../../resources/forecast-changes-history/forecast-changes-history-types';
import './index.scss';

interface Props {
  forecastHistory: ForecastChangesHistoryResponse;
}

export default function HistoryTable({ forecastHistory }: Props) {
  const yearMonths = useMemo(() => {
    if (forecastHistory === undefined) return [];

    return Array.from(
      new Set(forecastHistory.changes.flatMap(change => change.values.map(value => value.yearMonth))),
    ).sort((a, b) => a - b);
  }, [forecastHistory]);

  return (
    <div className="forecast-history-table-container fancy-scrollbar">
      <table className="forecast-history-table">
        <thead>
          <tr>
            <th className="sticky">Date Modified</th>
            <th className="sticky">Country</th>
            <th className="sticky">User ID</th>
            {yearMonths.map(yearMonth => (
              <th key={yearMonth}>
                {(yearMonth % 100).toString().padStart(2, '0') + '/' + Math.floor(yearMonth / 100)}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {forecastHistory.changes.map(change => (
            <tr key={`${change.userId}-${change.dateModified}`}>
              <td className="sticky">{new Date(change.dateModified).toLocaleString()}</td>
              <td className="sticky">{change.country}</td>
              <td className="sticky">{change.userId}</td>
              {yearMonths.map(yearMonth => {
                const valueObj = change.values.find(val => val.yearMonth === yearMonth);
                return (
                  <td key={yearMonth} className={valueObj ? valueObj.alteredPage.toLowerCase() : ''}>
                    <span className={valueObj ? valueObj.action.toLowerCase() : ''}>
                      {valueObj ? Number(valueObj.value.toFixed(0)).toLocaleString() : '-'}
                    </span>
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
