import { useState } from 'react';
import { ExternalData } from '../../@types/external-api';
import {
  makeExternalCallErrorData,
  makeExternalDataInitialData,
  makeExternalDataSuccessData,
} from '../../helpers/external-data';
import MacroGraphContext from './macro-graph-context';
import {
  IChartStyle,
  IMacroGraphRequest,
  IMacroGraphResponse,
  initialChartStyle,
  initialSelectedCells,
  ScenarioForecastResponse,
} from './macro-graph-types';
import macroGraphService from './macro-graph.service';

const AUTO_SCALE_THRESHOLD = 10000000;

const MacroGraphProvider: React.FC = ({ children }) => {
  const [chartRealTimeData, setMacroGraphChartRealTimeData] = useState<IMacroGraphResponse>();
  const [graphData, setGraphData] = useState<ExternalData<IMacroGraphResponse>>(makeExternalDataInitialData());
  const [chartStyle, setChartStyle] = useState<IChartStyle>(initialChartStyle);
  const [lastFilterGraphRequest, setLastFilterGraphRequest] = useState<IMacroGraphRequest | null>(null);
  const [changedScaleManually, setChangedScaleManually] = useState<boolean>(false);
  const [graphTitle, setGraphTitle] = useState<string>('');
  const [graphSubtitle, setGraphSubtitle] = useState<string>('');
  const [chartDataIsDirty, setChartDataIsDirty] = useState<boolean>(false);
  const [lineIds, setLineIds] = useState<number[]>([]);
  // initialize with 30 lines
  const [combinedSelectedCells, setCombinedSelectedCells] =
    useState<Record<number, Record<string, boolean>>>(initialSelectedCells);

  const [allDefaultScenarios, setAllDefaultScenarios] = useState<ExternalData<ScenarioForecastResponse>>(
    makeExternalDataInitialData(),
  );

  const updateRealTimeChartData = async () => {
    setMacroGraphChartRealTimeData(undefined);

    if (!lastFilterGraphRequest) return;

    const res = await macroGraphService.filterGraph({ ...lastFilterGraphRequest, hasDraftValues: true }).promise;

    setChartDataIsDirty(true);
    setMacroGraphChartRealTimeData(res);
  };

  const persistValues = async (req: { codes: number[] }) => {
    setMacroGraphChartRealTimeData(undefined);
    await macroGraphService.persistValues();
  };

  const filterGraph = async (req: IMacroGraphRequest) => {
    setLastFilterGraphRequest(req);
    setGraphData(makeExternalDataInitialData());

    if (!req.hasDraftValues) {
      void macroGraphService.deleteDraft();
      setMacroGraphChartRealTimeData(undefined);
    }

    const { promise, abort } = macroGraphService.filterGraph(req);

    try {
      const res = await promise;
      if (!changedScaleManually) {
        setChartStyle(prev => {
          return { ...prev, graphOptions: { ...prev.graphOptions, scaleResults: hasBigNumber(res) } };
        });
      }
      setGraphData(makeExternalDataSuccessData(res));
    } catch (e: any) {
      setGraphData(makeExternalCallErrorData(e));
    }

    return abort;
  };

  const hasBigNumber = (res: IMacroGraphResponse) => {
    return (
      res.graphs.some(graph => graph.lines.some(line => line.rows.some(row => row.value > AUTO_SCALE_THRESHOLD))) &&
      !res.graphs.some(graph => graph.lines.some(line => line.rows.some(row => row.value != 0 && row.value < 1000)))
    );
  };

  const fetchDefaultScenarios = async (finalYear: number) => {
    const { promise, abort } = macroGraphService.getDefaultScenarios(finalYear);
    setAllDefaultScenarios(makeExternalDataInitialData());
    try {
      const res = await promise;
      setAllDefaultScenarios(makeExternalDataSuccessData(res));
    } catch (e: any) {
      setAllDefaultScenarios(makeExternalCallErrorData(e));
    }

    return abort;
  };

  return (
    <MacroGraphContext.Provider
      value={{
        graphTitle,
        graphSubtitle,
        setGraphSubtitle,
        setGraphTitle,
        chartRealTimeData,
        updateRealTimeChartData,
        persistValues,
        filterGraph,
        graphData,
        setChartStyle,
        chartStyle,
        combinedSelectedCells,
        setCombinedSelectedCells,
        changedScaleManually,
        setChangedScaleManually,
        chartDataIsDirty,
        setChartDataIsDirty,
        allDefaultScenarios,
        fetchDefaultScenarios,
        lineIds,
        setLineIds,
      }}>
      {children}
    </MacroGraphContext.Provider>
  );
};

export default MacroGraphProvider;
