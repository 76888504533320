import { Header } from '@amway/react-components';
import { useCallback, useState } from 'react';
import { Environments } from '../../../@types/configuration';
import environment, { appTitle } from '../../../config/env';
import { Features } from '../../../config/permissions-features';
import { WithFeaturesProxy } from '../../../helpers/with-features-proxy';
import useAuth from '../../../resources/auth/auth-hook';
import PermissionsModal from './permissions-modal';

const shouldShowQaContainer = [Environments.DEV, Environments.QA].includes(environment.ENV);

interface ExposedProps {}

interface Props extends ExposedProps {
  onSettingsClick?: VoidFunction;
}

const NavBarComponent: React.FC<Props> = ({ onSettingsClick }) => {
  const { isAuthenticated, user } = useAuth();

  return (
    <Header
      title={appTitle}
      isAuthenticated={isAuthenticated ?? false}
      shouldShowQaContainer={shouldShowQaContainer}
      environment={environment.ENV}
      user={user}
      onSettingsClick={onSettingsClick}
    />
  );
};

export default WithFeaturesProxy<ExposedProps>(Features.ManageOwnPermissions)((props, hasFeature) => {
  const [isQaModalOpen, setIsQaModalOpen] = useState(false);

  const handleOpenQaModal = useCallback(() => {
    setIsQaModalOpen(true);
  }, []);

  const handleCloseQaModal = useCallback(() => {
    setIsQaModalOpen(false);
  }, []);

  if (hasFeature) {
    return (
      <>
        <NavBarComponent onSettingsClick={handleOpenQaModal} />
        {/* TODO: Create PermissionModal inside Storybook */}
        <PermissionsModal isOpen={isQaModalOpen} onClose={handleCloseQaModal} />
      </>
    );
  }

  return <NavBarComponent {...props} />;
});
