import { useState } from 'react';
import { ExternalData } from '../../@types/external-api';
import { NormalAlert } from '../../components/core/alert-card/alert-cards';
import { Item } from '../../components/ui/dropdownBtn';
import {
  makeExternalCallErrorData,
  makeExternalDataInitialData,
  makeExternalDataSuccessData,
} from '../../helpers/external-data';
import ImportForecastContext from './import-forecast-context';
import { ForecastNotApplied, GetTempleteRequest } from './import-forecast-types';
import importForecastService from './import-forecast.service';

const ImportForecastProvider: React.FC = ({ children }) => {
  const [allForecastNotApplied, setAllForecastNotApplied] = useState<ExternalData<ForecastNotApplied[]>>(
    makeExternalDataInitialData(),
  );
  const [dataTypes, setDataTypes] = useState<Item[]>([]);

  const importForecast = async (req: FormData) => {
    setAllForecastNotApplied(makeExternalDataInitialData());

    const { promise, abort } = importForecastService.importForecast(req);

    try {
      const res = await promise;
      setAllForecastNotApplied(makeExternalDataSuccessData(res));

      if (res.length > 0)
        NormalAlert(
          'Some forecast data was updated successfully, but some were blocked for the reasons mentioned on the page',
        );
      else NormalAlert('All forecast data has been updated successfully');
    } catch (e: any) {
      setAllForecastNotApplied(makeExternalCallErrorData(e));
    }

    return abort;
  };

  const fetchAllDataTypes = async () => {
    const { promise, abort } = importForecastService.getAllDataTypes();

    setDataTypes([]);
    const res = await promise;

    setDataTypes(res);

    return abort;
  };

  const downloadTemplate = async (req: GetTempleteRequest) => {
    const { promise, abort } = importForecastService.downloadTemplate(req);

    try {
      const res = await promise;
      const fileUrl = window.URL.createObjectURL(new Blob([res.data]));

      const link = document.createElement('a');
      link.href = fileUrl;
      link.setAttribute('download', 'import_template.xlsx');
      document.body.appendChild(link);
      link.click();

      window.URL.revokeObjectURL(fileUrl);
    } catch (e: any) {
      NormalAlert('Error while trying to download the template');
    }
  };

  return (
    <ImportForecastContext.Provider
      value={{
        importForecast,
        allForecastNotApplied,
        fetchAllDataTypes,
        dataTypes,
        downloadTemplate,
      }}>
      {children}
    </ImportForecastContext.Provider>
  );
};

export default ImportForecastProvider;
