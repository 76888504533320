import { ReactNode } from 'react';
import { Card, CloseButton } from 'react-bootstrap';
import './index.scss';

interface Props {
  children: ReactNode;
  title: string;
  isToShow: boolean;
  close: () => void;
  titleComponent?: ReactNode;
  style?: React.CSSProperties;
}

const SideWrapper: React.FC<Props> = ({ children, title, isToShow, close, titleComponent, style }) => {
  return isToShow ? (
    <Card className="side-wrapper fancy-scrollbar" style={style}>
      <Card.Title style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
          <CloseButton onClick={close} />
          <h2 className="side-wrapper-title">{title}</h2>
        </div>
        {titleComponent}
      </Card.Title>
      {children}
    </Card>
  ) : (
    <></>
  );
};

export default SideWrapper;
