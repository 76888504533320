import { CheckBox, CheckBoxOutlineBlank } from '@mui/icons-material';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import React from 'react';
import { Item } from '../../ui/dropdownBtn';

interface SelectAutoCompleteProps {
  items: Item[];
  onMultipleChange?: (value: Item[]) => void;
  onSingleChange?: (value: Item) => void;
  label?: string;
  placeholder?: string;
  multiple?: boolean;
  disable?: boolean;
  value: Item | Item[] | null;
  style?: React.CSSProperties;
}

const SelectAutoComplete: React.FC<SelectAutoCompleteProps> = ({
  items,
  onMultipleChange,
  onSingleChange,
  label,
  placeholder,
  multiple = false,
  disable = false,
  value,
  style,
}) => {
  const handleMultipleChange = (event: React.SyntheticEvent<Element, Event>, value: Item[] | Item | null) => {
    if (onMultipleChange && Array.isArray(value)) {
      onMultipleChange(value);
    }
  };

  const handleSingleChange = (event: React.SyntheticEvent<Element, Event>, value: Item | Item[] | null) => {
    if (onSingleChange && !Array.isArray(value) && value !== null) {
      onSingleChange(value);
    }
  };

  const filterOptions = createFilterOptions({
    stringify: (option: Item) => option.label,
  });

  return (
    <Autocomplete
      disabled={disable}
      multiple={multiple}
      options={items}
      style={style}
      value={value}
      getOptionLabel={option => option.label}
      filterOptions={filterOptions}
      onChange={multiple ? handleMultipleChange : handleSingleChange}
      renderInput={params => (
        <TextField
          {...params}
          label={label}
          placeholder={placeholder}
          variant="standard"
          InputProps={{
            ...params.InputProps,
            disableUnderline: true,
            style: {
              borderBottom: '1px solid rgb(205, 205, 205)',
            },
          }}
        />
      )}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox
            icon={<CheckBoxOutlineBlank />}
            checkedIcon={<CheckBox />}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {option.label}
        </li>
      )}
    />
  );
};

export default SelectAutoComplete;
