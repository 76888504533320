import axios from 'axios';
import { WithAbortFn } from '../../@types/external-api';
import { apiWrapper } from '../../config/api-wrapper';
import { EditedValue, FilterDataTypesReq, MaintenanceTableRow } from './maintenance-types';

class MaintenanceService {
  public filterDataTypes(req: FilterDataTypesReq): WithAbortFn<Promise<MaintenanceTableRow[]>> {
    const source = axios.CancelToken.source();

    return {
      promise: apiWrapper.api.get('/maintenance/filter', { params: req }).then(res => res.data),
      abort: source.cancel,
    };
  }

  public editValues(req: EditedValue[]) {
    return apiWrapper.api.post('/maintenance/edit', req);
  }
}

export default new MaintenanceService();
