import { createContext } from 'react';
import { Item } from '../../components/ui/dropdownBtn';
import { Features, Permission } from '../../config/permissions-features';
import { ExternalAction, ExternalData } from './../../@types/external-api';
import { ClaimPermissions, FeaturesMap } from './features-types';

export interface FeaturesCtxType {
  loading: boolean;
  updatePermissionsStatus: ExternalAction;
  permissions: Permission[];
  countries: string[];
  marketLdapGroups: Item[];
  featuresMap: ExternalData<FeaturesMap>;
  claimsPermissions: ExternalData<ClaimPermissions[]>;
  hasFeature: (resourcePermissions: Features) => boolean;
  // This feature should be enabled only on QA/DEV environments
  updatePermissions: (permissions: Permission[], countries: string[]) => void;
  fetchClaimsPermissions: () => void;
  fetchFeaturesMap: () => void;
  fetchMarketLdapGroup: () => void;
}

const PermissionsContext = createContext<FeaturesCtxType>(null as any);

export default PermissionsContext;
