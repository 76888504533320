import { Button } from '@amway/react-components';
import { Checkbox, FormControlLabel, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { NormalAlert } from '../../../components/core/alert-card/alert-cards';
import { IDistCostForecastSettings } from '../../../resources/dist-cost-forecast-settings/dist-cost-forecast-settings-types';
import distCostForecastSettingsService from '../../../resources/dist-cost-forecast-settings/dist-cost-forecast-settings.service';
import { defaultDistCostForecastSettings } from '../util';

interface Props {
  data: IDistCostForecastSettings;
}

export default function DistCostForecastSettingsCard({ data }: Props) {
  const [isUpdating, setIsUpdating] = useState<boolean>(false);

  const [manualRate, setManualRate] = useState<string>('');
  const [settingsChanged, setSettingsChanged] = useState<IDistCostForecastSettings>(defaultDistCostForecastSettings);

  useEffect(() => {
    if (data) {
      setSettingsChanged(data);
      setManualRate(data.manualRate.toLocaleString());
    }
  }, [data]);

  const onUpdateValues = async () => {
    setIsUpdating(true);
    try {
      await distCostForecastSettingsService.editDistCostForecastSettings({
        ...settingsChanged,
        manualRate: Number(manualRate),
      });
      NormalAlert('Dist Cost Forecast Settings edits saved successfully');
    } catch {
      NormalAlert('Error when saving dist cost forecast settings edits');
    }
    setIsUpdating(false);
  };

  return (
    <div className="dist-cost-forecast-settings-card">
      <FormControlLabel
        control={
          <Checkbox
            checked={settingsChanged.automaticUpdate}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setSettingsChanged(prev => ({ ...prev, automaticUpdate: e.target.checked }));
            }}
          />
        }
        label="Generate Forecast automatically"
      />
      <Row>
        <Col>
          <Typography fontSize="10px" color="detail-gray">
            Automatic Rate
          </Typography>
          <Form.Control className="container" value={settingsChanged.automaticRate} disabled={true} />
        </Col>
        <Col>
          <Typography fontSize="10px" color="detail-gray">
            Manual Rate
          </Typography>
          <Form.Control
            className="container"
            value={manualRate}
            onChange={e => setManualRate(e.target.value.replace(/[^0-9.,]/g, ''))}
          />
        </Col>
        <Col style={{ display: 'flex' }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={settingsChanged.overwriteManual}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setSettingsChanged(prev => ({ ...prev, overwriteManual: e.target.checked }));
                }}
              />
            }
            label="Use Manual Rate"
          />
        </Col>
        <Col style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
          <Button style={{ height: 'fit-content' }} onClick={onUpdateValues} disabled={isUpdating} loading={isUpdating}>
            Update
          </Button>
        </Col>
      </Row>
    </div>
  );
}
