import { Typography } from '@amway/react-components';
import { TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { NormalAlert } from '../../../../components/core/alert-card/alert-cards';
import DialogLayout from '../../../../components/core/dialog-layout';
import useCommon from '../../../../resources/common/common-hook';
import macroGraphService from '../../../../resources/macro-graph/macro-graph.service';

interface CreateSnapshotModalProps {
  isOpen: boolean;
  close: () => void;
}

const CreateSnapshotModal: React.FC<CreateSnapshotModalProps> = ({ isOpen, close }) => {
  const [snapshotName, setSnapshotName] = useState('');
  const [isSavingSnapshot, setIsSavingSnapshot] = useState(false);
  const [isError, setIsError] = useState(false);
  const { fetchAllFcastSources } = useCommon();

  const handleSaveSnapshot = async (name: string) => {
    setIsSavingSnapshot(true);
    setIsError(false);

    try {
      await macroGraphService.createSnapshot(name);
      setSnapshotName('');
      fetchAllFcastSources();
      close();
    } catch (e: any) {
      setIsError(true);
    }

    setIsSavingSnapshot(false);
  };

  useEffect(() => {
    if (isError) {
      NormalAlert('Failed to save snapshot');
      close();
    }
  }, [isError]);

  return (
    <DialogLayout
      open={isOpen}
      title={'Save Snapshot'}
      scapeActionText={'Close'}
      isLoading={isSavingSnapshot}
      scapeActionHandler={() => close()}
      confirmActionHandler={snapshotName.length > 0 ? () => handleSaveSnapshot(snapshotName) : undefined}
      confirmActionText={'Save'}>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <Typography>
          Saving for: <b>{new Date().toLocaleDateString()}</b>
        </Typography>
        <Typography className="mt-3">Snapshot Name</Typography>
        <TextField
          label={'Name'}
          placeholder={'Enter snapshot name'}
          variant="standard"
          InputProps={{
            disableUnderline: true,
            style: {
              borderBottom: '1px solid rgb(205, 205, 205)',
            },
          }}
          value={snapshotName}
          onChange={e => {
            if (e.target.value.length <= 20) {
              setSnapshotName(e.target.value);
            }
          }}
        />
      </div>
    </DialogLayout>
  );
};

export default CreateSnapshotModal;
