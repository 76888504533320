import { AxiosResponse } from 'axios';
import { apiWrapper } from '../../config/api-wrapper';
import { Note, NotesFilter } from './notes-types';

class NotesService {
  public async addNewNote(req: { note: Note }): Promise<AxiosResponse<any, any>> {
    const result = await apiWrapper.api.post('/note/add', {
      countryCode: req.note.countryCode,
      date: req.note.date,
      note: req.note.note,
      types: req.note.types,
      link: req.note.link,
    });
    return result;
  }

  public async filterNotes(req: { filters: NotesFilter }): Promise<AxiosResponse<any, any>> {
    const result = await apiWrapper.api.post('/note/filter', req.filters);
    return result;
  }

  public async saveNote(req: { note: Note }): Promise<AxiosResponse<any, any>> {
    const result = await apiWrapper.api.post('/note/save', {
      id: req.note.id,
      countryCode: req.note.countryCode,
      date: req.note.date,
      note: req.note.note,
      types: req.note.types,
      link: req.note.link,
    });
    return result;
  }

  public async deleteNote(req: { note: Note }): Promise<AxiosResponse<any, any>> {
    const result = await apiWrapper.api.post('/note/delete', {
      id: req.note.id,
    });
    return result;
  }
}

export default new NotesService();
