import { Typography } from '@amway/react-components';
import React, { MutableRefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import EditableYearMonthTable from '../../components/core/editable-yrMo-table';
import ExternalDataRenderer from '../../components/hocs/external-data-renderer';
import BrowseByCorpCodeSelector from '../../components/ui/browse-by-corp-code-selector';
import useCommon from '../../resources/common/common-hook';
import useMacroGraph from '../../resources/macro-graph/macro-graph-hook';
import { initialChartStyle } from '../../resources/macro-graph/macro-graph-types';
import MacroGraphChart from './chart';
import ComponentToPdf from './component-to-pdf';
import InputDataTab from './side-wrapper-tabs/input-data-tab';
import NotesTab from './side-wrapper-tabs/notes-tab';
import ThemeTab from './side-wrapper-tabs/theme-tab';
import Toolbar from './toolbar';
import { formatGraphLines } from './utils';

function MacroGraph() {
  const { graphData, chartStyle, setChartStyle, combinedSelectedCells } = useMacroGraph();
  const [initialFetch, setInitialFetch] = useState<boolean>(false);
  const [selectedTotalSum, setSelectedTotalSum] = useState<number>(0);
  const targetRef: MutableRefObject<HTMLDivElement | null> = useRef(null);

  const [browserByCorpCodeSelected, setBrowserByCorpCodeSelected] = useState('');
  const memorizedBrowserByCorpCodeSelected = useMemo(() => browserByCorpCodeSelected, [browserByCorpCodeSelected]);

  const [shouldShowTabs, setShouldShowTabs] = useState<{ [key: string]: boolean }>({
    'input-data': true,
    notes: false,
    theme: false,
  });

  const memorizedSelectedCells = useMemo(() => combinedSelectedCells, [combinedSelectedCells]);

  const closeTab = useCallback(
    (tabId: string) => {
      setShouldShowTabs({ ...shouldShowTabs, [tabId]: false });
    },
    [shouldShowTabs],
  );

  const {
    businessLines,
    categories,
    countriesTree,
    dataTypes,
    fcastSources,
    exchangeRateYearOptions,
    dataTypesMetadata,
    yearOptions,
    countries,
    fetchAllBusinessLines,
    fetchAllCategories,
    fetchAllCountriesTree,
    fetchAllDataTypes,
    fetchAllFcastSources,
    fetchAllExchangeRateYearOptions,
    fetchAllDataTypesMetadata,
    fetchAllYearOptions,
    fetchAllCountries,
  } = useCommon();

  useEffect(() => {
    if (yearOptions.length === 0) fetchAllYearOptions();
    if (businessLines.length === 0) fetchAllBusinessLines();
    if (categories.length === 0) fetchAllCategories();
    if (countriesTree.length === 0) fetchAllCountriesTree();
    if (dataTypes.length === 0) fetchAllDataTypes();
    if (fcastSources.length === 0) fetchAllFcastSources();
    if (exchangeRateYearOptions.length === 0) fetchAllExchangeRateYearOptions();
    if (dataTypesMetadata.length === 0) fetchAllDataTypesMetadata();
    if (countries.length === 0) fetchAllCountries();

    return () => {
      setChartStyle(initialChartStyle);
      setInitialFetch(false);
    };
  }, []);

  const renderGraphAndTables = useMemo(() => {
    return (
      <ExternalDataRenderer
        externalData={graphData}
        makeDataElement={data => {
          const lines = formatGraphLines(data, memorizedBrowserByCorpCodeSelected);

          const rows = lines.flatMap(line => line.rows);
          return rows?.length > 0 ? (
            <>
              <BrowseByCorpCodeSelector
                data={data}
                browserByCorpCodeSelected={memorizedBrowserByCorpCodeSelected}
                setBrowserByCorpCodeSelected={setBrowserByCorpCodeSelected}
              />
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  padding: '0 10px',
                }}>
                <MacroGraphChart macroGraph={lines} />
                {chartStyle.graphOptions.showTabulation &&
                  lines.map((dataset, index) => (
                    <EditableYearMonthTable
                      key={index}
                      data={data}
                      lines={lines}
                      rowNumber={index}
                      setSelectedTotalSum={setSelectedTotalSum}
                      browserByCorpCodeSelected={memorizedBrowserByCorpCodeSelected}
                    />
                  ))}
              </div>
            </>
          ) : (
            <Typography variant="heading" className="text-center">
              No data available
            </Typography>
          );
        }}
      />
    );
  }, [chartStyle.graphOptions.showTabulation, graphData, memorizedBrowserByCorpCodeSelected]);

  const totalSelectedCells = useMemo(() => {
    const tableWithSelectedCells = Object.entries(memorizedSelectedCells).find(
      ([key, value]) => Object.keys(value).length > 0,
    );
    if (tableWithSelectedCells === undefined) return 0;
    return Object.keys(tableWithSelectedCells[1]).length;
  }, [memorizedSelectedCells]);

  return (
    <div style={{ display: 'flex' }}>
      <Container>
        <Card>
          <Card.Body>
            <Row>
              <Col>
                <Typography variant="heading">Macro Graph</Typography>
              </Col>
              <Col>
                <div style={{ marginLeft: 'auto', width: 'max-content' }}>
                  <Toolbar
                    targetRef={targetRef}
                    shouldShowTabs={shouldShowTabs}
                    setShouldShowTabs={setShouldShowTabs}
                  />
                </div>
              </Col>
            </Row>

            {initialFetch && (
              <>
                <Row style={{ display: 'flex', justifyContent: 'center', gap: '20px' }}>
                  {renderGraphAndTables}
                  {chartStyle.graphOptions.showTabulation && !graphData.loading && (
                    <Row>
                      <table style={{ width: 'max-content' }}>
                        <tr>
                          <td
                            style={{
                              border: '1px solid black',
                              padding: '1px 15px',
                              minWidth: '25px',
                              background: 'var(--tertiary)',
                            }}>
                            <b>Sum</b>
                          </td>
                          <td style={{ border: '1px solid black', padding: '1px 15px', minWidth: '25px' }}>
                            {Math.round(selectedTotalSum).toLocaleString()}
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{
                              border: '1px solid black',
                              padding: '1px 15px',
                              minWidth: '25px',
                              background: 'var(--tertiary)',
                            }}>
                            <b>Average</b>{' '}
                          </td>
                          <td style={{ border: '1px solid black', padding: '1px 15px', minWidth: '25px' }}>
                            {selectedTotalSum
                              ? Math.round(
                                  selectedTotalSum / (totalSelectedCells === 0 ? 1 : totalSelectedCells),
                                ).toLocaleString()
                              : 0}
                          </td>
                        </tr>
                      </table>
                    </Row>
                  )}
                  <ComponentToPdf targetRef={targetRef} />
                </Row>
              </>
            )}
          </Card.Body>
        </Card>
      </Container>
      {yearOptions.length > 0 && exchangeRateYearOptions.length > 0 ? (
        <InputDataTab
          isToShow={shouldShowTabs['input-data']}
          close={() => closeTab('input-data')}
          setInitialFetch={setInitialFetch}
        />
      ) : (
        <></>
      )}
      <NotesTab isToShow={shouldShowTabs['notes']} close={() => closeTab('notes')} />
      <ThemeTab isToShow={shouldShowTabs['theme']} close={() => closeTab('theme')} />
    </div>
  );
}

export default React.memo(MacroGraph);
