import moment from 'moment';
import { Item } from '../../components/ui/dropdownBtn';
import { Note, NotesFilter } from './notes-types';

export const defaultNote: Note = {
  countryCode: '',
  date: new Date(),
  note: '',
  types: ['Miscellaneous'],
  link: '',
};

export const cleanDefaultNotesFilters = {
  startDate: moment().subtract(3, 'years').startOf('year').toDate(),
  endDate: moment().add(3, 'years').startOf('year').toDate(),
  countryCodes: [],
  types: [],
};

export const saveFiltersToStorage = (filters: NotesFilter, typeSelected: Item[], countriesSelected: string[]) => {
  const item = {
    filters,
    typeSelected,
    countriesSelected,
  };

  localStorage.setItem('saved-notes-filters', JSON.stringify(item));
};

export const getFiltersFromStorage = (): {
  filters: NotesFilter;
  typeSelected: Item[];
  countriesSelected: Item[];
} => {
  const savedFilters = localStorage.getItem('saved-notes-filters');
  if (savedFilters) return JSON.parse(savedFilters);

  const item = {
    filters: cleanDefaultNotesFilters,
    typeSelected: [],
    countriesSelected: [],
  };

  saveFiltersToStorage(cleanDefaultNotesFilters, [], []);
  return item;
};

export const allTypesOptions: Item[] = [
  {
    id: 'Miscellaneous',
    label: 'Miscellaneous',
  },
  {
    id: 'Product Launch',
    label: 'Product Launch',
  },
  {
    id: 'Sales Incentive',
    label: 'Sales Incentive',
  },
  {
    id: 'Local Event',
    label: 'Local Event',
  },
  {
    id: 'External Event',
    label: 'External Event',
  },
  {
    id: 'Price Change',
    label: 'Price Change',
  },
  {
    id: 'Promotion',
    label: 'Promotion',
  },
  {
    id: 'PI Change',
    label: 'PI Change',
  },
];
