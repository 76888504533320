import { Button, Typography } from '@amway/react-components';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Card, Container, Form, Row } from 'react-bootstrap';
import Select, { MultiValue } from 'react-select';
import { NormalAlert } from '../../components/core/alert-card/alert-cards';
import CorpCodeSelector from '../../components/core/multi-select-checkbox-corpcode-input';
import CurrencyFilterInput from '../../components/ui/currency-filter-option';
import { Item } from '../../components/ui/dropdownBtn';
import useCommon from '../../resources/common/common-hook';
import useDailyReport from '../../resources/daily-report/daily-report-hook';
import { GetDailyReportRequest } from '../../resources/daily-report/daily-report-types';
import { convertItemInOption, Option } from '../summary-report/util';
import './index.scss';

const currencyOptions: Item[] = [
  { id: 'ACTUAL', label: 'U.S $' },
  { id: 'Local', label: 'Local' },
  { id: 'BDGT DT', label: 'U.S.$ Bdgt Dt X RT' },
];

export default function DailyReportComponent() {
  const { countriesTree, fetchAllCountriesTree } = useCommon();
  const {
    dataTypes,
    businessLines,
    downloadDailyReport,
    sendToEmailDailyReport,
    fetchAllBusinessLines,
    fetchAllDataTypes,
  } = useDailyReport();

  const [reportName, setReportName] = useState<string>('Macro Daily Report');
  const [corpCodesSelected, setCorpCodesSelected] = useState<string[]>([]);
  const [clearCountries, setClearCountries] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState<string>(
    moment().year() + '-' + (moment().month() + 1).toString().padStart(2, '0'),
  );

  const [currencySelected, setCurrencySelected] = useState<Item>(currencyOptions[1]);
  const [currencyMonthSelected, setCurrencyMonthSelected] = useState<Item | undefined>();
  const [currencyYearSelected, setCurrencyYearSelected] = useState<Item | undefined>();

  const [dataTypeSelected, setDataTypeSelected] = useState<Item[]>([]);
  const [businessLineSelected, setBusinessLinesSelected] = useState<Item[]>([]);

  const [isLoadingDownload, setIsLoadingDownload] = useState(false);
  const [isLoadingEmail, setIsLoadingEmail] = useState(false);

  useEffect(() => {
    if (countriesTree.length === 0) fetchAllCountriesTree();
    if (dataTypes.length === 0) fetchAllBusinessLines();
    if (businessLines.length === 0) fetchAllDataTypes();
  }, []);

  const prepareData = () => {
    if (corpCodesSelected.length === 0 || dataTypeSelected.length === 0 || selectedMonth === '' || reportName === '') {
      NormalAlert('Fill in all the fields to generate the report');
      return null;
    }
    if (
      dataTypeSelected.map(dataType => dataType.id.toString()).includes('business_line') &&
      businessLineSelected.length === 0
    ) {
      NormalAlert('Choose at least one business line');
      return null;
    }
    if (
      currencySelected.id.toString() === 'BDGT DT' &&
      (currencyYearSelected === undefined ||
        Number(currencyYearSelected.id) === null ||
        Number(currencyYearSelected.id) === 0 ||
        Number(currencyYearSelected.id) === undefined)
    ) {
      NormalAlert('You must select a year for the currency exchange rate');
      return null;
    }

    const req: GetDailyReportRequest = {
      countryCodes: corpCodesSelected,
      dataTypes: dataTypeSelected.map(dataType => dataType.id.toString()),
      businessLines: businessLineSelected.map(dataType => dataType.id.toString()),
      yearMonth: Number(selectedMonth.replace('-', '')),
      reportName: reportName + '.xlsx',
      currencyOptions: {
        type: currencySelected.id.toString(),
        year: currencyYearSelected ? Number(currencyYearSelected.id) : null,
        month: currencyMonthSelected ? Number(currencyMonthSelected.id) : null,
      },
    };

    return req;
  };

  const handleDownload = async () => {
    const req = prepareData();

    if (req === null) {
      return;
    }

    NormalAlert('Preparing this report may take a while');

    setIsLoadingDownload(true);
    await downloadDailyReport(req);
    setIsLoadingDownload(false);
  };

  const handleEmail = async () => {
    const req = prepareData();

    if (req === null) {
      return;
    }

    setIsLoadingEmail(true);
    await sendToEmailDailyReport(req);
    setIsLoadingEmail(false);
  };

  const clearFilters = () => {
    setClearCountries(!clearCountries);
    setSelectedMonth(moment().year() + '-' + (moment().month() + 1).toString().padStart(2, '0'));
    setReportName('Macro Daily Report');

    setCurrencySelected(currencyOptions[1]);
    setCurrencyMonthSelected(undefined);
    setCurrencyYearSelected(undefined);

    setDataTypeSelected([]);
    setBusinessLinesSelected([]);
  };

  return (
    <Container>
      <Card>
        <Card.Body>
          <Typography variant="heading">Daily Report</Typography>
          <div className="filter-daily-report">
            <Row>
              <Typography fontSize="10px" color="detail-gray">
                Corp Codes
              </Typography>
              <CorpCodeSelector
                selectedCorpCodes={corpCodesSelected}
                setCorpCodesSelected={setCorpCodesSelected}
                clear={clearCountries}
              />
            </Row>
            <div>
              <Typography fontSize="10px" color="detail-gray">
                Report Month/Year
              </Typography>
              <Form.Control
                type="month"
                id="monthInput"
                className="input"
                value={selectedMonth}
                onChange={e => setSelectedMonth(e.target.value)}
              />
            </div>
            <Row></Row>
            <CurrencyFilterInput
              currencyOptions={currencyOptions}
              currencySelected={currencySelected}
              setCurrencySelected={setCurrencySelected}
              currencyMonthSelected={currencyMonthSelected}
              setCurrencyMonthSelected={setCurrencyMonthSelected}
              currencyYearSelected={currencyYearSelected}
              setCurrencyYearSelected={setCurrencyYearSelected}
            />
            <div style={{ marginBottom: '20px' }}>
              <Typography fontSize="10px" color="detail-gray">
                Data Types
              </Typography>
              <Select
                isSearchable={true}
                isMulti
                options={convertItemInOption(dataTypes)}
                isClearable={false}
                menuPortalTarget={document.body}
                menuPosition="fixed"
                placeholder=""
                value={convertItemInOption(dataTypeSelected)}
                onChange={(selectedOption: MultiValue<Option>) => {
                  setDataTypeSelected(selectedOption.map(option => ({ id: option.value, label: option.label })));
                }}
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderLeft: 0,
                    borderTop: 0,
                    borderRight: 0,
                    borderRadius: 0,
                    cursor: 'pointer',
                  }),
                }}
              />
            </div>
            {dataTypeSelected.map(dataType => dataType.id.toString()).includes('business_line') ? (
              <div style={{ marginBottom: '20px' }}>
                <Typography fontSize="10px" color="detail-gray">
                  Business Lines
                </Typography>
                <Select
                  isSearchable={true}
                  isMulti
                  options={convertItemInOption(businessLines)}
                  isClearable={false}
                  menuPortalTarget={document.body}
                  menuPosition="fixed"
                  placeholder=""
                  value={convertItemInOption(businessLineSelected)}
                  onChange={(selectedOption: MultiValue<Option>) => {
                    setBusinessLinesSelected(selectedOption.map(option => ({ id: option.value, label: option.label })));
                  }}
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      borderLeft: 0,
                      borderTop: 0,
                      borderRight: 0,
                      borderRadius: 0,
                      cursor: 'pointer',
                    }),
                  }}
                />
              </div>
            ) : (
              <></>
            )}
            <div>
              <Typography fontSize="10px" color="detail-gray">
                Report name:
              </Typography>
              <Form.Control
                className="input"
                value={reportName}
                onChange={e => setReportName(e.target.value)}
                maxLength={50}
              />
            </div>
            <div style={{ display: 'flex', marginTop: '12px', justifyContent: 'space-between' }}>
              <Button onClick={clearFilters} disabled={isLoadingDownload || isLoadingEmail}>
                Clear
              </Button>
              <Button onClick={handleEmail} loading={isLoadingEmail} disabled={isLoadingDownload || isLoadingEmail}>
                Email
              </Button>
              <Button
                onClick={handleDownload}
                loading={isLoadingDownload}
                disabled={isLoadingDownload || isLoadingEmail}>
                Download
              </Button>
            </div>
          </div>
        </Card.Body>
      </Card>
    </Container>
  );
}
