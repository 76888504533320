import { createContext } from 'react';
import { ExternalData } from '../../@types/external-api';
import { FilterDataTypesReq, MaintenanceTableRow } from './maintenance-types';

export interface MaintenanceCtxType {
  fetchMaintenanceTableRows: (req: FilterDataTypesReq) => Promise<VoidFunction>;
  maintenanceTableRows: ExternalData<MaintenanceTableRow[]>;
}

const MaintenanceContext = createContext<MaintenanceCtxType>(null as any);

export default MaintenanceContext;
