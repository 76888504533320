import axios from 'axios';
import { WithAbortFn } from '../../@types/external-api';
import { apiWrapper } from '../../config/api-wrapper';
import { IDistCostForecastSettings } from './dist-cost-forecast-settings-types';

class DistCostForecastSettingsService {
  public fetchDistCostForecastSettings(countryCode: string): WithAbortFn<Promise<IDistCostForecastSettings>> {
    const source = axios.CancelToken.source();

    return {
      promise: apiWrapper.api
        .get('/dist-cost-forecast-settings/filter', { params: { countryCd: countryCode } })
        .then(res => res.data),
      abort: source.cancel,
    };
  }

  public editDistCostForecastSettings(req: IDistCostForecastSettings) {
    return apiWrapper.api.post('/dist-cost-forecast-settings/edit', req);
  }

  public async createDistCostForecast() {
    await apiWrapper.api.post('/dist-cost-forecast-settings/create');
  }
}

export default new DistCostForecastSettingsService();
