import { BrowserRouter } from 'react-router-dom';
import AuthProvider from './resources/auth/auth-provider';
import CommonProvider from './resources/common/common-provider';
import CountryCodeAdminProvider from './resources/country-code-admin/country-code-admin-provider';
import CurrencyAdminProvider from './resources/currency-admin/currency-admin-provider';
import DailyReportProvider from './resources/daily-report/daily-report-provider';
import DistCostForecastSettingsProvider from './resources/dist-cost-forecast-settings/dist-cost-forecast-settings-provider';
import FeaturesProvider from './resources/features/features-provider';
import FinancialReportProvider from './resources/financial-report/financial-report-provider';
import ForecastChangesHistoryProvider from './resources/forecast-changes-history/forecast-changes-history-provider';
import ImportForecastProvider from './resources/import-forecast/import-forecast-provider';
import MacroGraphProvider from './resources/macro-graph/macro-graph-provider';
import MaintenanceProvider from './resources/maintenance/maintenance-provider';
import NotesProvider from './resources/notes/notes-provider';
import RankingReportProvider from './resources/ranking-report/ranking-report-provider';
import SummaryReportProvider from './resources/summary-report/summary-report-provider';
import Router from './router';

export default function App() {
  return (
    <BrowserRouter>
      <AuthProvider>
        <FeaturesProvider>
          <NotesProvider>
            <MacroGraphProvider>
              <MaintenanceProvider>
                <CommonProvider>
                  <DailyReportProvider>
                    <SummaryReportProvider>
                      <FinancialReportProvider>
                        <RankingReportProvider>
                          <DistCostForecastSettingsProvider>
                            <ForecastChangesHistoryProvider>
                              <CountryCodeAdminProvider>
                                <CurrencyAdminProvider>
                                  <ImportForecastProvider>
                                    <Router />
                                  </ImportForecastProvider>
                                </CurrencyAdminProvider>
                              </CountryCodeAdminProvider>
                            </ForecastChangesHistoryProvider>
                          </DistCostForecastSettingsProvider>
                        </RankingReportProvider>
                      </FinancialReportProvider>
                    </SummaryReportProvider>
                  </DailyReportProvider>
                </CommonProvider>
              </MaintenanceProvider>
            </MacroGraphProvider>
          </NotesProvider>
        </FeaturesProvider>
      </AuthProvider>
    </BrowserRouter>
  );
}
