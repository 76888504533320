import { createContext } from 'react';
import { ExternalData } from '../../@types/external-api';
import { ForecastChangesHistoryRequest, ForecastChangesHistoryResponse } from './forecast-changes-history-types';

export interface ForecastChangesHistoryCtxType {
  forecastHistory: ExternalData<ForecastChangesHistoryResponse>;
  getForecastHistory: (req: ForecastChangesHistoryRequest) => Promise<VoidFunction>;
}

const ForecastChangesHistoryContext = createContext<ForecastChangesHistoryCtxType>(null as any);

export default ForecastChangesHistoryContext;
