import { OktaAuth } from '@okta/okta-auth-js';
import { Environments } from '../@types/configuration';
import authService from '../resources/auth/auth.service';
import environment from './env';

const oktaAuth = new OktaAuth({
  issuer: environment.OKTA_ISSUER,
  clientId: environment.OKTA_CLIENT_ID,
  scopes: environment.OKTA_SCOPES,
  postLogoutRedirectUri: window.location.origin + '/logout/callback',
  redirectUri: window.location.origin + '/login/callback',
  pkce: true,
  devMode: [Environments.DEV].includes(environment.ENV),
  tokenManager: {
    storage: 'localStorage',
  },
  transformAuthState: async (oktaAuth, authState) => {
    authService.oktaAuthState = authState;

    if (!authState.isAuthenticated) {
      return authState;
    }

    // extra requirement: user must have valid Okta SSO session
    const user = await authService.getLoggedUser(oktaAuth);
    authState.isAuthenticated = !!user; // convert to boolean
    authState.user = user; // also store user object on authState
    // void authService.storeUser(user?.nativeid as string, user?.email, user?.fullName as string); // store user in our backend

    return authState;
  },
});

export default oktaAuth;
