import axios, { AxiosResponse } from 'axios';
import { WithAbortFn } from '../../@types/external-api';
import { apiWrapper } from '../../config/api-wrapper';
import { GetFinancialReportRequest } from './financial-report-types';

class FinancialReportService {
  public downloadFinancialReport(request: GetFinancialReportRequest): WithAbortFn<Promise<AxiosResponse<any, any>>> {
    const source = axios.CancelToken.source();

    return {
      promise: apiWrapper.api.post('/financial-report/download', request, { responseType: 'blob' }),
      abort: source.cancel,
    };
  }

  public sendToEmailFinancialReport(request: GetFinancialReportRequest): WithAbortFn<Promise<AxiosResponse>> {
    const source = axios.CancelToken.source();

    return {
      promise: apiWrapper.api.post('/financial-report/email', request),
      abort: source.cancel,
    };
  }
}

export default new FinancialReportService();
