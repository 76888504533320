import { createContext } from 'react';
import { CountryCodeAdminTree } from './country-code-admin-types';

export interface CountryCodeAdminCtxType {
  fetchCountryCodeAdminTree: () => Promise<VoidFunction>;
  countryCodeAdminTree: CountryCodeAdminTree[];
  setCountryCodeAdminTree: React.Dispatch<React.SetStateAction<CountryCodeAdminTree[]>>;
}

const CountryCodeAdminContext = createContext<CountryCodeAdminCtxType>(null as any);

export default CountryCodeAdminContext;
