import { createContext } from 'react';
import { ExternalData } from '../../@types/external-api';
import { CurrencyAdminRowsRequest, CurrencyAdminTableResponse } from './currency-admin-types';

export interface CurrencyAdminCtxType {
  fetchCurrencyAdminTable: (req: CurrencyAdminRowsRequest) => Promise<VoidFunction>;
  currencyAdminTableResponse: ExternalData<CurrencyAdminTableResponse>;
}

const CurrencyAdminContext = createContext<CurrencyAdminCtxType>(null as any);

export default CurrencyAdminContext;
