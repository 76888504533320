import { LogoLoading } from '@amway/react-components';
import { ExternalCallError, ExternalData } from '../../../@types/external-api';

interface Props<T> {
  externalData: ExternalData<T>;
  makeDataElement: (data: T) => JSX.Element | any;
  makeLoadingElement?: () => JSX.Element;
  makeErrorElement?: (error: ExternalCallError) => JSX.Element;
  makeCustomIsLoading?: () => boolean;
}

const ExternalDataRenderer = <T extends {}>(props: Props<T>): JSX.Element => {
  const {
    externalData,
    makeLoadingElement = () => <LogoLoading />,
    makeErrorElement = (error: ExternalCallError) => (
      <span>
        <b>Error on fetching data:</b> {error.message}...
      </span>
    ),
    makeDataElement = () => <span>Data element is not set.</span>,
    makeCustomIsLoading = () => props.externalData.loading,
  } = props;

  if (externalData.error) {
    return makeErrorElement(externalData.error);
  } else if (makeCustomIsLoading()) {
    return makeLoadingElement();
  }

  return makeDataElement(externalData.data!);
};

export default ExternalDataRenderer;
