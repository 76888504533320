import { Button, Typography } from '@amway/react-components';
import { useEffect, useState } from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { Environments } from '../../@types/configuration';
import { ConfirmGeneralAction, NormalAlert } from '../../components/core/alert-card/alert-cards';
import CorpCodeSelector from '../../components/core/multi-select-checkbox-corpcode-input';
import ExternalDataRenderer from '../../components/hocs/external-data-renderer';
import environment from '../../config/env';
import useCommon from '../../resources/common/common-hook';
import useDistCostForecastSettings from '../../resources/dist-cost-forecast-settings/dist-cost-forecast-settings-hook';
import distCostForecastSettingsService from '../../resources/dist-cost-forecast-settings/dist-cost-forecast-settings.service';
import DistCostForecastSettingsCard from './dist-cost-forecast-settings-card';
import './index.scss';

export default function DistCostForecastSettings() {
  const [initialFetch, setInitialFetch] = useState<boolean>(false);
  const [createForecastLoading, setCreateForecastLoading] = useState<boolean>(false);

  const { countries, fetchAllCountries } = useCommon();
  const { distCostForecastSettings, fetchDistCostForecastSettings } = useDistCostForecastSettings();
  const [corpCodesSelected, setCorpCodesSelected] = useState<string[]>([]);

  useEffect(() => {
    if (countries.length === 0) fetchAllCountries();
  }, []);

  const createForecast = async () => {
    setCreateForecastLoading(true);
    await distCostForecastSettingsService.createDistCostForecast();
    setCreateForecastLoading(false);
  };

  const handleSerachButton = () => {
    if (corpCodesSelected.length === 0) {
      NormalAlert('Select the country code');
      return;
    }
    setInitialFetch(true);
    fetchDistCostForecastSettings(corpCodesSelected[0]);
  };

  return (
    <Container>
      <Card>
        <Card.Body>
          <Row>
            <Typography variant="heading">Dist Cost Forecast Settings</Typography>
            <Typography weight="bold" color="text-gray">
              Here you can modify the settings for the dist cost forecast for each country{' '}
            </Typography>
          </Row>
          <div className="dist-cost-settings-options">
            <div className="filter">
              <div>
                <Typography fontSize="10px" color="detail-gray">
                  Corp Code
                </Typography>
                <div className="corp-code-selector">
                  <CorpCodeSelector
                    selectedCorpCodes={corpCodesSelected}
                    setCorpCodesSelected={setCorpCodesSelected}
                    clear={false}
                    multi={false}
                  />
                </div>
              </div>
              <Col>
                <Button
                  onClick={handleSerachButton}
                  disabled={distCostForecastSettings.loading && initialFetch}
                  loading={distCostForecastSettings.loading && initialFetch}>
                  Search
                </Button>
              </Col>
            </div>
            {environment.ENV === Environments.DEV || environment.ENV === Environments.QA ? (
              <div>
                <Button
                  onClick={() => {
                    ConfirmGeneralAction(
                      'Do you really want to create a Dist Cost Forecast for all countries with the "Generate Forecast automatically" tag selected?',
                      'Dist Cost Forecast created successfully',
                      'Error creating the Dist Cost Forecast',
                      createForecast,
                    );
                  }}
                  disabled={createForecastLoading}
                  loading={createForecastLoading}>
                  Create Dist Cost Forecast
                </Button>
              </div>
            ) : (
              <></>
            )}
          </div>
          {initialFetch && (
            <Row className="mt-5">
              <ExternalDataRenderer
                externalData={distCostForecastSettings}
                makeDataElement={data => <DistCostForecastSettingsCard data={data} />}
              />
            </Row>
          )}
        </Card.Body>
      </Card>
    </Container>
  );
}
