import { useState } from 'react';
import { Item } from '../../components/ui/dropdownBtn';
import CommonContext from './common-context';
import { DataTypeMetadata, GroupedOption, MultiSelectCheckboxOption } from './common-types';
import commonService from './common.service';

const CommonProvider: React.FC = ({ children }) => {
  const [dataTypes, setDataTypes] = useState<GroupedOption[]>([]);
  const [yearOptions, setYearOptions] = useState<Item[]>([]);
  const [dataTypesMetadata, setDataTypesMetadata] = useState<DataTypeMetadata[]>([]);
  const [fcastSources, setFcastSources] = useState<Item[]>([]);
  const [businessLines, setBusinessLines] = useState<Item[]>([]);
  const [categories, setCategories] = useState<Item[]>([]);
  const [countries, setCountries] = useState<Item[]>([]);
  const [countriesTree, setCountriesTree] = useState<MultiSelectCheckboxOption[]>([]);
  const [exchangeRates, setExchangeRates] = useState<{ name: string; value: string }[]>([]);
  const [exchangeRateYearOptions, setExchangeRateYearOptions] = useState<Item[]>([]);

  const fetchAllDataTypes = async () => {
    const { promise, abort } = commonService.getAllDataTypes();

    setDataTypes([]);
    const res = await promise;

    setDataTypes(res);

    return abort;
  };

  const fetchAllYearOptions = async () => {
    const { promise, abort } = commonService.getAllYearOptions();

    setYearOptions([]);
    const res = await promise;

    setYearOptions(res);

    return abort;
  };

  const fetchAllDataTypesMetadata = async () => {
    const { promise, abort } = commonService.getAllDataTypesMetadata();

    setDataTypesMetadata([]);
    const res = await promise;

    setDataTypesMetadata(res);

    return abort;
  };

  const fetchAllFcastSources = async () => {
    const { promise, abort } = commonService.fetchAllFcastSources();

    setDataTypes([]);
    const res = await promise;

    setFcastSources(res);

    return abort;
  };

  const fetchAllBusinessLines = async () => {
    const { promise, abort } = commonService.getAllBusinessLines();

    setBusinessLines([]);
    const res = await promise;

    setBusinessLines(res);

    return abort;
  };

  const fetchAllCategories = async () => {
    const { promise, abort } = commonService.getAllCategories();

    setCategories([]);
    const res = await promise;

    setCategories(res);

    return abort;
  };

  const fetchAllExchangeRateYearOptions = async () => {
    const { promise, abort } = commonService.getAllExchangeRateYearOptions();

    setExchangeRateYearOptions([]);
    const res = await promise;
    setExchangeRateYearOptions(
      res.sort((a, b) => {
        return Number(a.id) - Number(b.id);
      }),
    );

    return abort;
  };

  const fetchAllCountries = async () => {
    const { promise, abort } = commonService.getAllCountries();

    setCountries([]);
    const res = await promise;
    res.sort((a, b) => a.code.localeCompare(b.code));
    const allCountries = res.map(country => {
      return {
        id: country.code,
        label: country.code + ' - ' + country.name,
      };
    });

    setCountries(allCountries);

    return abort;
  };

  const fetchAllCountriesTree = async () => {
    const { promise, abort } = commonService.getAllCountriesTree();

    setCountriesTree([]);
    const res = await promise;

    setCountriesTree(res);

    return abort;
  };

  const fetchAllExchangeRates = async () => {
    const { promise, abort } = commonService.getAllExchangeRates();

    setExchangeRates([]);
    const res = await promise;

    setExchangeRates(res);

    return abort;
  };

  return (
    <CommonContext.Provider
      value={{
        dataTypes,
        businessLines,
        categories,
        countries,
        countriesTree,
        exchangeRateYearOptions,
        exchangeRates,
        fcastSources,
        dataTypesMetadata,
        yearOptions,
        fetchAllDataTypes,
        fetchAllBusinessLines,
        fetchAllCategories,
        fetchAllCountries,
        fetchAllCountriesTree,
        fetchAllExchangeRates,
        fetchAllFcastSources,
        fetchAllExchangeRateYearOptions,
        fetchAllDataTypesMetadata,
        fetchAllYearOptions,
      }}>
      {children}
    </CommonContext.Provider>
  );
};

export default CommonProvider;
