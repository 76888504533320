import Swal from 'sweetalert2';

export const NormalAlert = (text: string) => {
  Swal.fire(text);
};

export const ConfirmDeleteAction = (objectName: string, deleteFunction: () => Promise<void>) => {
  Swal.fire({
    title: 'Are you sure?',
    text: "You won't be able to revert this!",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Yes, delete it!',
  }).then(async result => {
    if (result.isConfirmed) {
      await deleteFunction();
      Swal.fire({
        title: 'Deleted!',
        text: objectName + ' has been deleted',
        icon: 'success',
      });
    }
  });
};

export const ConfirmGeneralAction = (
  text: string,
  textPostActionSucess: string,
  textPostActionError: string,
  generalFunction: () => Promise<void>,
) => {
  Swal.fire({
    text: text,
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Confirm',
  }).then(async result => {
    if (result.isConfirmed) {
      await generalFunction()
        .then(() => NormalAlert(textPostActionSucess))
        .catch(() => NormalAlert(textPostActionError));
    }
  });
};
