import { Note, NotesFilter } from './notes-types';
import NotesContext from './notes-context';
import notesService from './notes.service';
import { useState } from 'react';
import { ExternalData } from '../../@types/external-api';
import { makeExternalDataInitialData, makeExternalDataSuccessData } from '../../helpers/external-data';
import { getFiltersFromStorage } from './utils';

const NotesProvider: React.FC = ({ children }) => {

  const [filterFields, setFilterFields] = useState<NotesFilter>(getFiltersFromStorage().filters);
  
  const [filteredNotes, setFilteredNotes] = useState<ExternalData<Note[]>>(
    makeExternalDataInitialData(),
  );

  const addNewNote = async (red: {note: Note}) => {
    return await notesService.addNewNote(red);
  }
  
  const filterNotes = async (red: {filters: NotesFilter}) => {

    setFilteredNotes(makeExternalDataInitialData);
    const res = await notesService.filterNotes(red);
    const allNotes:Note[] = res.data;
    
    allNotes.sort((a, b) => (new Date(b.date)).getTime() - (new Date(a.date)).getTime());

    setFilteredNotes(makeExternalDataSuccessData(allNotes));
  }

  const saveNote = async (red: {note: Note}) => {
    return await notesService.saveNote(red);
  }

  const deleteNote = async (red: {note: Note}) => {
    await notesService.deleteNote(red);
  }


  return (
    <NotesContext.Provider
      value={{
        addNewNote,
        filterNotes,
        saveNote,
        deleteNote,
        filteredNotes,
        filterFields,
        setFilterFields
      }}>
      {children}
    </NotesContext.Provider>
  );
};

export default NotesProvider;
