import { ForecastNotApplied } from '../../../resources/import-forecast/import-forecast-types';

interface Props {
  forecastNotApplied: ForecastNotApplied;
}

export default function ForecastNotAppliedCard({ forecastNotApplied }: Props) {
  return (
    <div className="unsaved-forecast">
      <p>
        <b>Country Code:</b> {forecastNotApplied.countryCode}
      </p>
      <p>
        <b>Reason:</b> {forecastNotApplied.reason}
      </p>
      {forecastNotApplied.yearMonths != null && forecastNotApplied.yearMonths.length > 0 ? (
        <p>
          <b>All unsaved years/months:</b> {forecastNotApplied.yearMonths.join('; ')}
        </p>
      ) : (
        <></>
      )}
    </div>
  );
}
