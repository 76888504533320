import Tree, {
  ItemId,
  moveItemOnTree,
  mutateTree,
  RenderItemParams,
  TreeData,
  TreeDestinationPosition,
  TreeItem,
  TreeSourcePosition,
} from '@atlaskit/tree';
import { useEffect, useState } from 'react';
import {
  CountryCodeAdminTree,
  CountryCodeRelationship,
} from '../../../resources/country-code-admin/country-code-admin-types';
import CountryCodeItem from '../country-code-item';
import { convertToTreeData, createTemplateItems, getNewEditedElements, getNewTreeWithNewItem } from '../util';

interface Props {
  itemAdded: TreeItem | undefined;
  data: CountryCodeAdminTree[];
  itemsEditedIds: string[];
  setItemsEditedIds: React.Dispatch<React.SetStateAction<string[]>>;
  allItemsIdAdded: ItemId[];
  allCountriesToUpdateInfo: CountryCodeRelationship[];
  setAllCountriesToUpdateInfo: React.Dispatch<React.SetStateAction<CountryCodeRelationship[]>>;
  setAllItemsToDelete: React.Dispatch<React.SetStateAction<number[]>>;
  isSavingChanges: boolean;
}

export default function DragAndDropCorpCodeHierarchy({
  data,
  itemAdded,
  itemsEditedIds,
  setItemsEditedIds,
  allItemsIdAdded,
  allCountriesToUpdateInfo,
  setAllCountriesToUpdateInfo,
  setAllItemsToDelete,
  isSavingChanges,
}: Props) {
  const [tree, setTree] = useState<TreeData>(convertToTreeData(data));

  useEffect(() => {
    if (itemAdded !== undefined) {
      const newTree = getNewTreeWithNewItem(tree, itemAdded);
      if (newTree) {
        setTree(newTree);
      }
    }
  }, [itemAdded]);

  const onExpand = (itemId: ItemId) => {
    setTree(mutateTree(tree, itemId, { isExpanded: true }));
  };

  const onCollapse = (itemId: ItemId) => {
    setTree(mutateTree(tree, itemId, { isExpanded: false }));
  };

  const onDragEnd = (source: TreeSourcePosition, destination?: TreeDestinationPosition) => {
    if (!destination) {
      return;
    }

    if (destination.parentId === 'root') {
      return;
    }

    const newTree = moveItemOnTree(tree, source, destination);
    const newParent = newTree.items[destination.parentId];
    if (!newParent.hasChildren) {
      newTree.items[destination.parentId].hasChildren = true;
    }

    if (destination.index !== undefined) {
      const itemParent = newTree.items[destination.parentId];
      const itemId = itemParent.children[destination.index];
      if (!itemsEditedIds.includes(itemId.toString())) {
        setItemsEditedIds(prev => [...prev, itemId.toString()]);
      }
    }

    createTemplateItems(newTree);
    const newEditedElements = getNewEditedElements(newTree, allCountriesToUpdateInfo, destination.parentId);
    setAllCountriesToUpdateInfo(newEditedElements);
    setTree(newTree);
  };

  function deleteItemAndChildren(treeData: TreeData, itemId: ItemId): TreeData {
    const item = treeData.items[itemId];

    if (!item) {
      return treeData;
    }

    if (!allItemsIdAdded.includes(itemId) && (item.data.isTemplate === undefined || !item.data.isTemplate)) {
      setAllItemsToDelete(prev => [...prev, Number(itemId.toString())]);
    }

    setAllCountriesToUpdateInfo(prev =>
      prev.filter(countryRelationship => countryRelationship.id !== Number(itemId.toString())),
    );
    setItemsEditedIds(prev => prev.filter(editedId => editedId !== itemId.toString()));

    item.children.forEach(childId => {
      treeData = deleteItemAndChildren(treeData, childId);
    });

    delete treeData.items[itemId];

    Object.values(treeData.items).forEach(parentItem => {
      parentItem.children = parentItem.children.filter(childId => childId !== itemId);
    });

    return treeData;
  }

  const deleteItem = (itemId: ItemId) => {
    const newTree = deleteItemAndChildren(tree, itemId);
    setTree(newTree);
  };

  const renderItem = ({ item, onExpand, onCollapse, provided }: RenderItemParams) => {
    return (
      <CountryCodeItem
        itemParams={{
          item,
          onExpand,
          onCollapse,
          provided,
          depth: 0,
          snapshot: undefined,
        }}
        deleteItem={deleteItem}
        itemsEditedIds={itemsEditedIds}
        setItemsEditedIds={setItemsEditedIds}
        allCountriesToUpdateInfo={allCountriesToUpdateInfo}
        setAllCountriesToUpdateInfo={setAllCountriesToUpdateInfo}
      />
    );
  };

  return (
    <Tree
      tree={tree}
      renderItem={renderItem}
      onExpand={onExpand}
      onCollapse={onCollapse}
      onDragEnd={onDragEnd}
      offsetPerLevel={24}
      isDragEnabled={!isSavingChanges}
    />
  );
}
