import './index.scss';

export default function NotFoundComponent() {
  return (
    <div className="not-found">
      <h1>404</h1>
      <h2>Page not found</h2>
      <h4>We couldn't find the page you were looking for.</h4>
      <h4>Make sure you have proper access, the URL is correct and that you're connected to the VPN</h4>
    </div>
  );
}
