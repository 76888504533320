import { useState } from 'react';
import CountryCodeAdminContext from './country-code-admin-context';
import { CountryCodeAdminTree } from './country-code-admin-types';
import countryCodeAdminTreeService from './country-code-admin.service';

const CountryCodeAdminProvider: React.FC = ({ children }) => {
  const [countryCodeAdminTree, setCountryCodeAdminTree] = useState<CountryCodeAdminTree[]>([]);

  const fetchCountryCodeAdminTree = async () => {
    setCountryCodeAdminTree([]);

    const { promise, abort } = countryCodeAdminTreeService.fetchCountryCodeAdminTree();

    try {
      const res = await promise;
      setCountryCodeAdminTree([res]);
    } catch (e: any) {
      setCountryCodeAdminTree([]);
    }

    return abort;
  };

  return (
    <CountryCodeAdminContext.Provider
      value={{
        fetchCountryCodeAdminTree,
        countryCodeAdminTree,
        setCountryCodeAdminTree,
      }}>
      {children}
    </CountryCodeAdminContext.Provider>
  );
};

export default CountryCodeAdminProvider;
