import { createContext } from 'react';
import { ExternalData } from '../../@types/external-api';
import { IDistCostForecastSettings } from './dist-cost-forecast-settings-types';

export interface DistCostForecastSettingsCtxType {
  fetchDistCostForecastSettings: (req: string) => Promise<VoidFunction>;
  distCostForecastSettings: ExternalData<IDistCostForecastSettings>;
}

const DistCostForecastSettingsContext = createContext<DistCostForecastSettingsCtxType>(null as any);

export default DistCostForecastSettingsContext;
