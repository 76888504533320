import axios from 'axios';
import { WithAbortFn } from '../../@types/external-api';
import { apiWrapper } from '../../config/api-wrapper';
import { CurrencyAdminRowsRequest, CurrencyAdminTableResponse, EditedValue } from './currency-admin-types';

class CurrnecyAdminService {
  public fetchCurrencyAdminTable(req: CurrencyAdminRowsRequest): WithAbortFn<Promise<CurrencyAdminTableResponse>> {
    const source = axios.CancelToken.source();

    return {
      promise: apiWrapper.api.post('/currency-maintenance/filter', req).then(res => res.data),
      abort: source.cancel,
    };
  }

  public editValues(req: EditedValue[]) {
    return apiWrapper.api.post('/currency-maintenance/edit', req);
  }
}

export default new CurrnecyAdminService();
