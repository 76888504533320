import React from 'react';
import Select, { SingleValue } from 'react-select';
import { Option } from '../util';

interface Props {
  items: Option[];
  value: Option | null;
  onChange: (value: Option | null) => void;
}

const FcastSelector: React.FC<Props> = ({ items, value, onChange }) => {
  return (
    <Select
      isSearchable={true}
      options={items}
      isClearable={false}
      menuPortalTarget={document.body}
      menuPosition="fixed"
      placeholder=""
      value={value}
      onChange={(selectedOption: SingleValue<Option>) => {
        onChange(selectedOption);
      }}
      styles={{
        control: (baseStyles, state) => ({
          ...baseStyles,
          borderLeft: 0,
          borderTop: 0,
          borderRight: 0,
          borderRadius: 0,
          cursor: 'pointer',
        }),
      }}
    />
  );
};

export default FcastSelector;
