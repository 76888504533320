import moment from 'moment';
import { ILineConfiguration } from '../../../resources/macro-graph/macro-graph-types';

export const formatDataTypeName = (dataType: string) => {
  return dataType
    .split('_')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

export const formatYAxis = (tick: number) => {
  if (tick < 1000) return tick.toFixed(2);
  if (tick < 1000000) return `${(tick / 1000).toFixed(0)}K`;
  if (tick < 1000000000) return `${(tick / 1000000).toFixed(0)}M`;
  return `${(tick / 1000000000).toFixed(1)}B`;
};

export const formatXAxisTrend = (tick: number) => {
  const year = ((tick / 100) % 100).toFixed(0);
  const monthNumber = tick % 100;
  const monthName = moment()
    .month(monthNumber - 1)
    .format('MMM');

  return monthName + ' ' + year;
};

export const toPercent = (decimal: number) => `${decimal * 100}%`;

export const getPercent = (value: number, total: number) => {
  const ratio = total > 0 ? value / total : 0;
  return `${(ratio * 100).toFixed(2)}%`;
};

export const getYAxisLabel = (lineConfigurations: ILineConfiguration[], scale: string) => {
  const result = Array.from(
    new Set(lineConfigurations.filter(line => line.scale === scale).map(line => line.dataType)),
  );
  if (result.length === 1) {
    return formatDataTypeName(result[0]);
  }
  return '';
};
