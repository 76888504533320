import { createContext } from 'react';
import { Item } from '../../components/ui/dropdownBtn';
import { GetDailyReportRequest } from './daily-report-types';

export interface DataTypeCtxType {
  dataTypes: Item[];
  businessLines: Item[];
  fetchAllDataTypes: () => void;
  fetchAllBusinessLines: () => void;
  downloadDailyReport: (request:GetDailyReportRequest) => Promise<void>;
  sendToEmailDailyReport: (request:GetDailyReportRequest) => Promise<void>;
}

const DataTypeContext = createContext<DataTypeCtxType>(null as any);

export default DataTypeContext;
