export const dataTypesInfo = [
  {
    title: 'REVENUE',
    description:
      'Revenue comes from Finance. Data is generated monthly both prelim and final figures are generated out of Hyperion. The value is typically similar to the Macro DC but will include all financial transactions such as renewal fees and meeting fees. Also, revenue is net of returns. Since this is a financial value, it is based on ships rather than orders, so Backorders are credited when shipped, and EOM orders are credited when shipped. The Revenue figure is also subject to financial adjustments which are typically not in the DC value.  Future forecast values are generated by the Center Macro Forecast team.',
  },
  {
    title: 'REVENUE – MAT Forecast',
    description: 'Same as Revenue above, except future forecast values are generated by MAT (market analytic team).',
  },
  {
    title: 'REVENUE – MODEL FORECAST',
    description: 'Same as Revenue above, except future forecast values are generated by ML or Statistical Models.',
  },
  {
    title: 'DC Total (incl Catalog)',
    description:
      'This is the total of all sales including Distributor Cost sales of all products and application fees but excludes renewal fees, meeting fees. Macro DC does not net returns. Including Catalog sales. ',
  },
  {
    title: 'DC Total (excl Catalog)',
    description:
      'This is the total of all sales including Distributor Cost sales of all products and application fees but excludes renewal fees, meeting fees. Macro DC does not net returns. Excluding Catalog sales. ',
  },
  {
    title: 'SMOOTH REVENUE',
    description: 'It is loaded manually into FC Macro.',
  },
  {
    title: 'DIST COST NEW/IMPROV (12 MOS.)',
    description:
      'Total sales of items that have launched in the prior rolling 12-month period. The sales are measured from month 1 out through month 12 of product availability. This metric is used to support and measure the ITM impact of project launches. The data is derived from FC Micro.',
  },
  {
    title: 'DISTRIBUTOR COST - Micro',
    description:
      'Total sales aggregated at market level, sum of all products’ micro demand multiplied by unit price (unit price is not adjusted for discounts). DC Planning forecast only includes skus on the micro/o9 file. Also, like the Macro DC description above, renewal fees, meeting fees and returns are excluded because there is no sku assigned in our system. One clarification, if a promo sku is used on the FC file then this would reflect promo / discounted pricing. Also note there can be issues when Appraise price changes lag, which is a common occurrence.',
  },
  {
    title: 'MAX',
    description:
      'The maximum end of the revenue range for a given affiliate is determined by corporate forecasting. The Max for the current calendar year is usually positioned just prior to the start of the current calendar year. This Max forecast range is typically used for the Max or 2X payout threshold for determining ACIP targets. Also, the Max is ‘frozen’ for a given year once the calendar year commences but is reviewed and revised on a quarterly basis for outgoing years.',
  },
  {
    title: 'MIN',
    description:
      'The minimum end of the revenue range for a given affiliate is determined by corporate forecasting. The Min for the current calendar year is usually positioned just prior to the start of the current calendar year. This Min forecast range is typically used for the Min or 50% payout threshold for determining ACIP targets. Also, the Min is ‘frozen’ for a given year once the calendar year commences but is reviewed and revised on a quarterly basis for outgoing years.',
  },
  {
    title: 'REVENUE TARGET (BUDGET)',
    description:
      'The Revenue Target is the Sales target for a country determined by the OCE/Board. This Sales target determines the top line calculation for ACIP payouts to employees. It is based on the Sales Forecast for Revenue and is frozen for the calendar year.',
  },
];

export const exchangeRatesData = [
  {
    title: 'U.S.$',
    description:
      'Applies actual exchange rates month/year to historical periods and projected exchange rates month/year for future months. All month/year exchange rates are provided by Ada Finance and updated monthly to FCMacro database.',
  },
  {
    title: 'U.S.$ BDGT X RT',
    description:
      'Consistent (fixed) budget exchange rate for each month within a given calendar year. Budget rate for each calendar year is applied to horizon of report (i.e. budget 15 rate applied to CY15 report data, budget 16 rate applied to CY16 report data, etc..). Budget rates are established by Ada Finance and updated in FCMacro database during Q4 of year prior.',
  },
  {
    title: 'U.S. $ ACTUAL BDGT X RT',
    description:
      'Applies actual month/year exchange rate to all historical data within report horizon. Applies budget fixed month/year exchange rates for all future months of report horizon.',
  },
  {
    title: 'U.S. $ BDGT DT X RT',
    description:
      'Applies specific fixed budget month/year exchange rate for the month/year selected to entire horizon of report.',
  },
  {
    title: 'U.S. $ DATED X RT',
    description:
      'Applies specific fixed actual month/year exchange rate for the month/year selected to entire horizon of report.',
  },
];
