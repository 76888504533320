import { createContext } from 'react';
import { Note, NotesFilter } from './notes-types';
import { AxiosResponse } from 'axios';
import { ExternalData } from '../../@types/external-api';


export interface NoteCtxType {
  filteredNotes: ExternalData<Note[]>;
  filterFields: NotesFilter;
  setFilterFields: (filter: NotesFilter) => void;
  addNewNote: (req: { note: Note }) => Promise<AxiosResponse<any, any>>;
  filterNotes: (req: { filters: NotesFilter }) => Promise<void>;
  saveNote: (req: { note: Note }) => Promise<AxiosResponse<any, any>>;
  deleteNote: (req: { note: Note }) => Promise<void>;
}

const NotesContext = createContext<NoteCtxType>(null as any);

export default NotesContext;
