import React from 'react';
import Select, { MultiValue, SingleValue } from 'react-select';
import { DataTypeOption, GroupedOption } from '../../resources/common/common-types';

interface Props {
  disable?: boolean;
  multiple?: boolean;
  items: GroupedOption[];
  value: DataTypeOption | DataTypeOption[] | null;
  handleSingleChange?: (value: SingleValue<DataTypeOption>) => void;
  handleMultipleChange?: (value: MultiValue<DataTypeOption>) => void;
}

const DataTypeSelector: React.FC<Props> = ({
  disable = false,
  multiple = false,
  items,
  value,
  handleSingleChange,
  handleMultipleChange,
}) => {
  const handleChange = (selectedOption: SingleValue<DataTypeOption> | MultiValue<DataTypeOption>) => {
    if (multiple) {
      handleMultipleChange?.(selectedOption as MultiValue<DataTypeOption>);
    } else {
      handleSingleChange?.(selectedOption as SingleValue<DataTypeOption>);
    }
  };

  return (
    <>
      {multiple ? (
        <Select
          isMulti
          isSearchable={true}
          isDisabled={disable}
          menuPortalTarget={document.body}
          menuPosition="fixed"
          placeholder="Select one or more data types"
          options={items}
          value={value}
          styles={{
            control: (baseStyles, state) => ({
              ...baseStyles,
              borderLeft: 0,
              borderTop: 0,
              borderRight: 0,
              borderRadius: 0,
              cursor: disable ? 'not-allowed' : 'pointer',
            }),
          }}
          onChange={handleChange}
        />
      ) : (
        <Select
          isSearchable={true}
          isDisabled={disable}
          options={items}
          isClearable={false}
          menuPortalTarget={document.body}
          menuPosition="fixed"
          placeholder=""
          value={value}
          onChange={handleChange}
          styles={{
            control: (baseStyles, state) => ({
              ...baseStyles,
              borderLeft: 0,
              borderTop: 0,
              borderRight: 0,
              borderRadius: 0,
              cursor: disable ? 'not-allowed' : 'pointer',
            }),
          }}
        />
      )}
    </>
  );
};

export default DataTypeSelector;
