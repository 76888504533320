import { Button } from '@amway/react-components';
import ReactDataGrid from '@inovua/reactdatagrid-community';
import moment from 'moment';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Row } from 'react-bootstrap';
import { ConfirmGeneralAction } from '../../../components/core/alert-card/alert-cards';
import { EditedValue, MaintenanceTableRow } from '../../../resources/maintenance/maintenance-types';
import maintenanceService from '../../../resources/maintenance/maintenance.service';
import { corpReferHeaders, dataTypeColumns, formatDate, formatRows } from '../utils';

interface Props {
  data: MaintenanceTableRow[];
  onResetData: () => void;
}

export default function CorpReferTable({ data, onResetData }: Props) {
  const [selectedCells, setSelectedCells] = useState<Record<string, boolean>>({});
  const [lockedCells, setLockedCells] = useState<string[]>([]);
  const [editedValues, setEditedValues] = useState<EditedValue[]>([]);
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const [isEditingValue, setIsEditingValue] = useState<boolean>(false);

  const formattedData = useMemo(() => {
    return formatRows(data);
  }, [data]);

  // Used to render the floating menu when right click cell
  const renderRowContextMenu = (menuProps: any, { rowProps, cellProps }: any) => {
    const cellId = `${cellProps?.data.id},${cellProps?.id}`;
    const cellColumn = cellProps.id;

    if (cellColumn === 'year' || cellColumn === 'month' || cellColumn === 'corp') {
      return;
    }

    let cellIdsToBeEdited: string[] = [];
    if (Object.keys(selectedCells).length < 2) {
      cellIdsToBeEdited = [cellId];
    } else {
      cellIdsToBeEdited = Object.keys(selectedCells)
        .filter(cell => selectedCells[cell])
        .map(cell => cell);
    }

    // check if all selected cells are locked or unlocked
    const allSelectedCellsLocked = cellIdsToBeEdited.every(yearMonth => lockedCells.includes(yearMonth));
    const allSelectedCellsUnlocked = cellIdsToBeEdited.every(yearMonth => !lockedCells.includes(yearMonth));

    if (!allSelectedCellsLocked && !allSelectedCellsUnlocked) {
      return;
    }

    const shouldUnlock = lockedCells.includes(cellId);

    menuProps.autoDismiss = true;
    menuProps.items = [
      {
        label: shouldUnlock ? 'Unlock' : 'Lock',
        onClick: () => {
          if (shouldUnlock) {
            setLockedCells(prev => prev.filter(cell => !cellIdsToBeEdited.includes(cell)));
          } else {
            setLockedCells(prev => [...prev, ...cellIdsToBeEdited]);
          }
        },
      },
    ];
  };

  useEffect(() => {
    const allCellsIds = data.flatMap(row =>
      dataTypeColumns.flatMap(dataType => {
        if (moment(`${row.year}-${row.month}`).isBefore(moment())) {
          return `${row.corp},${formatDate(`${row.year}-${row.month}`)},${dataType}`;
        } else {
          return [];
        }
      }),
    );
    setLockedCells(allCellsIds);
  }, []);

  const onCellSelectionChange = useCallback((newSelectedCells: Record<string, boolean>) => {
    // const [corpCd, yrMonth, columnName] = Object.keys(newSelectedCells)[0].split(',');
    const allColumnNames = Object.keys(newSelectedCells).map(key => key.split(',')[2]);

    if (allColumnNames.some(columnName => columnName === 'month' || columnName === 'year' || columnName === 'corp')) {
      return;
    }

    setSelectedCells(newSelectedCells);
  }, []);

  const onEditComplete = useCallback(
    async ({ value, columnId, rowIndex }) => {
      const yr = formattedData[rowIndex].year;
      const month = formattedData[rowIndex].month;
      const corpCd = formattedData[rowIndex].corp;
      const yrMonth = formatDate(`${yr}-${month}`);
      const editedValue = {
        countryCd: corpCd,
        yrMo: Number(yrMonth),
        dataType: columnId,
        value: Number(value),
      };

      setEditedValues(prev => [...prev, editedValue]);

      // @ts-expect-error
      formattedData[rowIndex][columnId] = value;

      setTimeout(() => {
        setIsEditingValue(false);
      }, 1000);
    },
    [formattedData],
  );

  const handleSaveChanges = () => {
    ConfirmGeneralAction(
      'Do you really want to save the changes?',
      'Changes saved successfully',
      'Error saving changes, please try again.',
      onUpdateValues,
    );
  };

  const onUpdateValues = useCallback(async () => {
    setIsUpdating(true);
    await maintenanceService.editValues(editedValues);
    setEditedValues([]);
    setIsUpdating(false);
  }, [editedValues]);

  return (
    <Row>
      <div style={{ display: 'flex', width: '100%', justifyContent: 'end', gap: 15, marginBottom: 25 }}>
        <Button
          disabled={isUpdating || editedValues.length < 1}
          loading={isUpdating}
          backgroundColor="warning-success"
          onClick={handleSaveChanges}>
          Update
        </Button>
        <Button variant="link" fontColor="warning-error" onClick={onResetData}>
          Reset
        </Button>
      </div>
      <ReactDataGrid
        idProperty="id"
        style={{ height: '600px' }}
        reorderColumns={false}
        dataSource={formattedData}
        columns={corpReferHeaders(lockedCells, editedValues, isEditingValue)}
        editable
        cellSelection={selectedCells}
        onCellSelectionChange={onCellSelectionChange}
        renderRowContextMenu={renderRowContextMenu}
        onEditStart={() => setIsEditingValue(true)}
        onEditComplete={onEditComplete}
        showColumnMenuLockOptions={false}
        showColumnMenuGroupOptions={false}
        showColumnMenuFilterOptions={false}
        showGroupSummaryRow={false}
      />
    </Row>
  );
}
