import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import env from './env';

class ApiWrapper {
  private readonly axiosInstance: AxiosInstance;

  get api(): AxiosInstance {
    return this.axiosInstance;
  }

  get requestConfig(): AxiosRequestConfig {
    return {
      headers: {
        Authorization: this.axiosInstance.defaults.headers.common.Authorization,
      },
    };
  }

  constructor(apiConfig: AxiosRequestConfig) {
    this.axiosInstance = axios.create(apiConfig);
  }

  setAuthToken(token: string): void {
    this.axiosInstance.defaults.headers.common.Authorization = `Bearer ${token}`;
  }

  clearAuthToken(): void {
    delete this.axiosInstance.defaults.headers.common.Authorization;
  }

  setOktaThing(authToken: string, idToken: string, nonce?: string) {
    this.axiosInstance.defaults.headers.common.Authorization = `Bearer ${authToken}`;
    this.axiosInstance.defaults.headers.common['id_token'] = idToken;
    this.axiosInstance.defaults.headers.common.nonce = nonce as string;
  }

  clearOktaThing() {
    delete this.axiosInstance.defaults.headers.common.Authorization;
    delete this.axiosInstance.defaults.headers.common['id_token'];
    delete this.axiosInstance.defaults.headers.common.nonce;
  }
}

export const apiWrapper = new ApiWrapper({ baseURL: env.API_URL });
