import axios from 'axios';
import { WithAbortFn } from '../../@types/external-api';
import { apiWrapper } from '../../config/api-wrapper';
import {
  EditRawValuesRequest,
  EditTotalValuesRequest,
  EditTotalValuesResponse,
  IMacroGraphRequest,
  IMacroGraphResponse,
  ITotalValues,
  LockUnlockFieldRequest,
  ScenarioForecastResponse,
} from './macro-graph-types';

class MacroGraphService {
  public async editRawValues(req: EditRawValuesRequest): Promise<ITotalValues> {
    const res = await apiWrapper.api.post('/graph/edit/raw-values', req);
    return res.data;
  }

  public async editTotalValue(req: EditTotalValuesRequest): Promise<EditTotalValuesResponse> {
    const res = await apiWrapper.api.post('/graph/edit/total', req);
    return res.data;
  }

  public async deleteDraft(): Promise<void> {
    await apiWrapper.api.post('/graph/edit/delete');
  }

  public async lockUnlockValues(req: LockUnlockFieldRequest): Promise<void> {
    await apiWrapper.api.post('/graph/lock-unlock', req);
  }

  public async persistValues(): Promise<void> {
    await apiWrapper.api.post('/graph/edit/persist-values');
  }

  public filterGraph(req: IMacroGraphRequest): WithAbortFn<Promise<IMacroGraphResponse>> {
    const source = axios.CancelToken.source();

    return {
      promise: apiWrapper.api.post('/graph/filter', req).then(res => res.data),
      abort: source.cancel,
    };
  }

  public async createSnapshot(name: string): Promise<void> {
    await apiWrapper.api.post('/graph/save/manual-snapshot', {}, { params: { name } });
  }

  public getDefaultScenarios(finalYear: number): WithAbortFn<Promise<ScenarioForecastResponse>> {
    const source = axios.CancelToken.source();

    return {
      promise: apiWrapper.api
        .get('/scenario-forecast/get-default-scenarios', { params: { finalYear } })
        .then(res => res.data),
      abort: source.cancel,
    };
  }
}

export default new MacroGraphService();
