import { Item } from '../../components/ui/dropdownBtn';
import { DataTypeOption } from '../../resources/common/common-types';
import { DataTypeSourceRequest } from '../../resources/summary-report/summary-report-types';

export interface DataTypeSourceOptions {
  dataType: DataTypeOption | null;
  fcastSource: Item | null;
}

export const sortByOptions: Item[] = [
  {
    id: 'code',
    label: 'Corp code',
  },
  {
    id: 'name',
    label: 'Corp Name',
  },
  {
    id: 'region_code',
    label: 'Region. Corp code',
  },
  {
    id: 'region_name',
    label: 'Region. Corp Name',
  },
];

export const defaultDataTypeSourceOption: DataTypeSourceOptions = {
  dataType: null,
  fcastSource: { id: 'Current Month', label: 'Current Month' },
};

export const convertDataTypeSourceOptionsInRequest = (options: DataTypeSourceOptions[]) => {
  const requests: DataTypeSourceRequest[] = [];

  options.forEach(option => {
    if (option.dataType != null) {
      requests.push({
        dataType: option.dataType.value,
        fcastSource: option.fcastSource ? option.fcastSource.id.toString() : '',
      });
    }
  });

  return requests;
};

export interface Option {
  readonly label: string;
  readonly value: string;
}

export const convertItemInOption = (items: Item[]): Option[] => {
  return items.map(item => {
    return { value: item.id.toString(), label: item.label };
  });
};
