import React, { useCallback } from 'react';
import { formatXAxisTrend, getPercent } from '../utils';
import './index.scss';

interface Entry {
  value?: number;
  name?: string;
  color?: string;
}

interface TooltipContentProps {
  active?: boolean;
  payload?: Entry[];
  label?: string;
  showTotal?: boolean;
}

interface RenderTooltipContentProps {
  isSeasonal?: boolean;
  active?: boolean;
  payload?: Entry[];
  label?: string;
  showTotal?: boolean;
}

const TrendTooltipContent: React.FC<TooltipContentProps> = ({ active, payload, label, showTotal = false }) => {
  if (!active || !payload || payload.length === 0) return null;

  const sortedPayload = [...payload].sort((a, b) => (b.value || 0) - (a.value || 0));
  const total = sortedPayload.reduce((result, entry) => result + (entry.value || 0), 0);
  return (
    <div className="custom-tooltip">
      <p className="label">
        {showTotal
          ? `${formatXAxisTrend(Number(label))} (Total: ${total.toFixed(2)})`
          : `Date: ${formatXAxisTrend(Number(label))}`}
      </p>
      {sortedPayload.map((entry, index) => (
        <p key={`item-${index}`} style={{ color: entry.color }}>
          {`${entry.name?.substring(0, 15)}${
            showTotal ? `: ${entry.value} (${getPercent(entry.value!, total)})` : `...: ${entry.value}`
          }`}
        </p>
      ))}
    </div>
  );
};

const SeasonalTooltipContent: React.FC<TooltipContentProps> = ({ active, payload, label }) => {
  if (!active || !payload || payload.length === 0) return null;

  const sortedPayload = [...payload].sort((a, b) => (b.value || 0) - (a.value || 0));

  return (
    <div className="custom-tooltip">
      <p className="label">{label}</p>
      {sortedPayload.map((entry, index) => (
        <p key={`item-${index}`} style={{ color: entry.color }}>
          {`${entry.name}: ${entry.value}`}
        </p>
      ))}
    </div>
  );
};

export const RenderTooltipContent: React.FC<RenderTooltipContentProps> = ({ showTotal, isSeasonal, ...props }) => {
  const renderContent = useCallback(() => {
    if (isSeasonal) return <SeasonalTooltipContent {...props} />;
    return <TrendTooltipContent {...props} showTotal={showTotal} />;
  }, [isSeasonal, showTotal, props]);

  return <>{renderContent()}</>;
};
