import { useEffect, useMemo } from 'react';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import useCommon from '../../../resources/common/common-hook';
import { IMacroGraphResponse } from '../../../resources/macro-graph/macro-graph-types';
import { getCountryName } from '../../../screens/macro-graph/utils';
import DropdownBtn from '../dropdownBtn';
import './index.scss';

interface Props {
  data: IMacroGraphResponse;
  browserByCorpCodeSelected: string;
  setBrowserByCorpCodeSelected: (value: React.SetStateAction<string>) => void;
}

export default function BrowseByCorpCodeSelector({
  data,
  browserByCorpCodeSelected,
  setBrowserByCorpCodeSelected,
}: Props) {
  const { countries } = useCommon();

  const lineWithCorpCode = useMemo(() => data.graphs.find(graph => graph.browserByCorpCode), [data]);

  let browserCorpCodeOptions = useMemo(() => {
    if (lineWithCorpCode !== undefined) {
      return lineWithCorpCode.lines
        .map(line => line.countryCodes[0])
        .map(countryCode => ({
          id: countryCode,
          label: getCountryName(countries, countryCode),
        }));
    }
    return [];
  }, [lineWithCorpCode]);

  const currentIndexSelected = useMemo(() => {
    if (browserByCorpCodeSelected === '' && lineWithCorpCode !== undefined) return 0;
    return browserCorpCodeOptions.findIndex(item => item.id === browserByCorpCodeSelected);
  }, [browserByCorpCodeSelected]);

  const handleBackButton = () => {
    if (currentIndexSelected !== -1 && currentIndexSelected > 0) {
      setBrowserByCorpCodeSelected(browserCorpCodeOptions[currentIndexSelected - 1].id.toString());
    }
  };

  const handleNextButton = () => {
    if (currentIndexSelected !== -1 && currentIndexSelected < browserCorpCodeOptions.length - 1) {
      setBrowserByCorpCodeSelected(browserCorpCodeOptions[currentIndexSelected + 1].id.toString());
    }
  };

  useEffect(() => {
    if (lineWithCorpCode !== undefined) {
      setBrowserByCorpCodeSelected(browserCorpCodeOptions[0].id.toString());
    }
  }, [browserCorpCodeOptions]);

  return (
    <>
      {lineWithCorpCode !== undefined && browserCorpCodeOptions.length > 0 ? (
        <div className="browse-by-corp-code-selector">
          <button className="back-next-button" onClick={handleBackButton} disabled={currentIndexSelected <= 0}>
            <IoIosArrowBack fontSize={24} />
          </button>
          <button
            className="back-next-button"
            onClick={handleNextButton}
            disabled={currentIndexSelected == browserCorpCodeOptions.length - 1}>
            <IoIosArrowForward fontSize={24} />
          </button>
          <DropdownBtn
            id="choose-browse-by-corp-code-line"
            styleType="filter"
            label="Browse by corp code"
            placeholder="Select"
            items={browserCorpCodeOptions}
            value={
              browserByCorpCodeSelected !== ''
                ? browserCorpCodeOptions.find(item => item.id === browserByCorpCodeSelected)
                : browserCorpCodeOptions[0]
            }
            onClick={item => {
              if (item) {
                setBrowserByCorpCodeSelected(item.id.toString());
              }
            }}
          />
        </div>
      ) : (
        <></>
      )}
    </>
  );
}
