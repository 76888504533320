import { FaLink } from 'react-icons/fa6';
import { TbPointFilled } from 'react-icons/tb';
import { Note } from '../../../../../resources/notes/notes-types';
import './index.scss';

interface Props {
  note: Note;
  handleNoteClicked: (note: Note) => void;
}

const NoteCard: React.FC<Props> = ({ note, handleNoteClicked }) => {
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
  };

  return (
    <div className="note-card" onClick={() => handleNoteClicked(note)}>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <p>{new Date(note.date.toString()).toLocaleDateString()}</p>
        <button hidden={note.link === null || note.link === ''} className="link-button" onClick={handleClick}>
          <a href={note.link} target="_blank" rel="noreferrer">
            <FaLink />
          </a>
        </button>
      </div>
      <h4>{note.note}</h4>
      <span>
        CORP {note.countryCode} <TbPointFilled /> {note.types.join('; ')}
      </span>
    </div>
  );
};

export default NoteCard;
