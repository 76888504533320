import { createContext } from 'react';
import { ExternalData } from '../../@types/external-api';
import { Item } from '../../components/ui/dropdownBtn';
import { ForecastNotApplied, GetTempleteRequest } from './import-forecast-types';

export interface ImportForecastCtxType {
  importForecast: (req: FormData) => Promise<VoidFunction>;
  allForecastNotApplied: ExternalData<ForecastNotApplied[]>;
  dataTypes: Item[];
  fetchAllDataTypes: () => void;
  downloadTemplate: (req: GetTempleteRequest) => Promise<void>;
}

const ImportForecastContext = createContext<ImportForecastCtxType>(null as any);

export default ImportForecastContext;
