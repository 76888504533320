import axios from 'axios';
import { WithAbortFn } from '../../@types/external-api';
import { apiWrapper } from '../../config/api-wrapper';
import { ForecastChangesHistoryRequest, ForecastChangesHistoryResponse } from './forecast-changes-history-types';

class ForecastChangesHistoryService {
  public getForecastHistory(req: ForecastChangesHistoryRequest): WithAbortFn<Promise<ForecastChangesHistoryResponse>> {
    const source = axios.CancelToken.source();

    return {
      promise: apiWrapper.api.post('/forecast-history/', req).then(res => res.data),
      abort: source.cancel,
    };
  }
}

export default new ForecastChangesHistoryService();
