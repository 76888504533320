import { AxiosError } from 'axios';
import { NormalAlert } from '../../components/core/alert-card/alert-cards';
import FinancialReportContext from './financial-report-context';
import { GetFinancialReportRequest } from './financial-report-types';
import FinancialReportService from './financial-report.service';

const FinancialReportProvider: React.FC = ({ children }) => {
  const downloadFinancialReport = async (request: GetFinancialReportRequest) => {
    const { promise, abort } = FinancialReportService.downloadFinancialReport(request);

    try {
      const res = await promise;
      const fileUrl = window.URL.createObjectURL(new Blob([res.data]));

      const link = document.createElement('a');
      link.href = fileUrl;
      link.setAttribute('download', request.reportName);
      document.body.appendChild(link);
      link.click();

      window.URL.revokeObjectURL(fileUrl);
    } catch (e: any) {
      NormalAlert('Error while trying to create the report');
    }
  };

  const sendToEmailFinancialReport = async (request: GetFinancialReportRequest) => {
    const { promise, abort } = FinancialReportService.sendToEmailFinancialReport(request);

    try {
      const res = await promise;
      NormalAlert('You will receive your email soon');
    } catch (error: any) {
      const axiosError = error as AxiosError;
      if (axiosError.response) NormalAlert(axiosError.response.data.error);
      else NormalAlert('Error when trying to send the email');
    }
  };

  return (
    <FinancialReportContext.Provider
      value={{
        downloadFinancialReport,
        sendToEmailFinancialReport,
      }}>
      {children}
    </FinancialReportContext.Provider>
  );
};

export default FinancialReportProvider;
