import axios, { AxiosResponse } from 'axios';
import { WithAbortFn } from '../../@types/external-api';
import { apiWrapper } from '../../config/api-wrapper';
import { GetSummaryReportRequest } from './summary-report-types';

class SummaryReportService {
  public downloadSummaryReport(request: GetSummaryReportRequest): WithAbortFn<Promise<AxiosResponse<any, any>>> {
    const source = axios.CancelToken.source();

    return {
      promise: apiWrapper.api.post('/summary-report/download', request, { responseType: 'blob' }),
      abort: source.cancel,
    };
  }

  public sendToEmailSummaryReport(request: GetSummaryReportRequest): WithAbortFn<Promise<AxiosResponse>> {
    const source = axios.CancelToken.source();

    return {
      promise: apiWrapper.api.post('/summary-report/email', request),
      abort: source.cancel,
    };
  }
}

export default new SummaryReportService();
