import { LogoLoading } from '@amway/react-components';
import React from 'react';
import './index.scss';

const LoadingScreen: React.FC = () => {
  return (
    <main className="loading-screen">
      <LogoLoading />
    </main>
  );
};

export default LoadingScreen;
