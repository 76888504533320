import { Button } from '@amway/react-components';
import { ItemId, RenderItemParams, TreeItem } from '@atlaskit/tree';
import moment from 'moment';
import { useMemo, useState } from 'react';
import { Form } from 'react-bootstrap';
import { CiTrash } from 'react-icons/ci';
import { CountryCodeRelationship } from '../../../resources/country-code-admin/country-code-admin-types';

interface Props {
  itemParams: RenderItemParams;
  deleteItem: (id: ItemId) => void;
  itemsEditedIds: string[];
  setItemsEditedIds: React.Dispatch<React.SetStateAction<string[]>>;
  allCountriesToUpdateInfo: CountryCodeRelationship[];
  setAllCountriesToUpdateInfo: React.Dispatch<React.SetStateAction<CountryCodeRelationship[]>>;
}

export default function CountryCodeItem({
  itemParams,
  deleteItem,
  itemsEditedIds,
  setItemsEditedIds,
  allCountriesToUpdateInfo,
  setAllCountriesToUpdateInfo,
}: Props) {
  const item = useMemo(() => {
    return itemParams.item;
  }, [itemParams]);

  const countriestoUpdateIndex = useMemo(() => {
    return allCountriesToUpdateInfo.findIndex(
      itemChanged => item.data && itemChanged.id.toString() === item.id.toString(),
    );
  }, [item, allCountriesToUpdateInfo]);

  const [title, setTitle] = useState(item.data ? item.data.title : '');

  const getIcon = (item: TreeItem, onExpand: (itemId: ItemId) => void, onCollapse: (itemId: ItemId) => void) => {
    return item.isExpanded ? (
      <span className="pre-text-icon" onClick={() => onCollapse(item.id)}>
        -
      </span>
    ) : (
      <span className="pre-text-icon" onClick={() => onExpand(item.id)}>
        +
      </span>
    );
  };

  const handleItemTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!itemsEditedIds.includes(item.id.toString())) {
      setItemsEditedIds(prev => [...prev, item.id.toString()]);
    }

    if (countriestoUpdateIndex !== -1) {
      allCountriesToUpdateInfo[countriestoUpdateIndex] = {
        ...allCountriesToUpdateInfo[countriestoUpdateIndex],
        name: e.target.value,
      };
      setAllCountriesToUpdateInfo(allCountriesToUpdateInfo);
    } else {
      setAllCountriesToUpdateInfo(prev => [...prev, { ...item.data.countryCodeRelationship, name: e.target.value }]);
    }

    setTitle(e.target.value);
  };

  return (
    <>
      {item.data && !item.data.isTemplate ? (
        <div
          ref={itemParams.provided.innerRef}
          {...itemParams.provided.draggableProps}
          {...itemParams.provided.dragHandleProps}
          className={!itemsEditedIds.includes(item.id.toString()) ? 'tree-item' : 'tree-item edited'}>
          <span>{getIcon(item, itemParams.onExpand, itemParams.onCollapse)}</span>
          {item.data && item.data.countryCodeRelationship.countryCd ? (
            <Form.Control
              id={'code-input-' + item.id}
              className="input code-input"
              value={item.data ? item.data.countryCodeRelationship.countryCd : ''}
              disabled={true}
            />
          ) : (
            <></>
          )}
          <Form.Control
            id={'title-input-' + item.id}
            className="input title-input"
            onChange={handleItemTitleChange}
            value={title}
          />
          {item.data && item.data.countryCodeRelationship.launchDate ? (
            <Form.Control
              id={'launch-date-input-' + item.id}
              className="input launch-date-input"
              value={item.data ? moment(item.data.countryCodeRelationship.launchDate).format('MM/YYYY') : ''}
              disabled={true}
            />
          ) : (
            <></>
          )}
          {item.data && item.data.countryCodeRelationship.parent != null ? (
            <Button className="delete-button" onClick={() => deleteItem(item.id)}>
              <CiTrash />
            </Button>
          ) : (
            <></>
          )}
        </div>
      ) : (
        <div
          ref={itemParams.provided.innerRef}
          {...itemParams.provided.draggableProps}
          {...itemParams.provided.dragHandleProps}></div>
      )}
    </>
  );
}
