import { useCallback, useEffect, useState } from 'react';
import { ExternalAction, ExternalData } from '../../@types/external-api';
import { Item } from '../../components/ui/dropdownBtn';
import { Features, Permission } from '../../config/permissions-features';
import {
  makeExternalCallErrorData,
  makeExternalCallSuccessData,
  makeExternalDataInitialData,
  makeExternalDataSuccessData,
} from '../../helpers/external-data';
import useAuth from '../auth/auth-hook';
import PermissionsContext, { FeaturesCtxType } from './features-context';
import { ClaimPermissions, FeaturesMap } from './features-types';
import { displayFeaturesGracefully, displayPermissionsGracefully } from './features-utils';
import permissionsService from './permissions.service';

const FeaturesProvider: React.FC = ({ children }) => {
  //FIXME: change to isAuth service with okta
  const { isAuthenticated } = useAuth();
  const [updatePermissionsStatus, setUpdatePermissionsStatus] = useState<ExternalAction>({});
  const [countries, setCountries] = useState<string[]>([]);
  const [marketLdapGroups, setMarketLdapGroups] = useState<Item[]>([]);
  const [permissions, setPermissions] = useState<Permission[]>([]);
  const [featuresMap, setFeaturesMap] = useState<ExternalData<FeaturesMap>>(makeExternalDataInitialData());
  const [claimsPermissions, setClaimsPermissions] = useState<ExternalData<ClaimPermissions[]>>(
    makeExternalDataInitialData(),
  );
  const [features, setFeatures] = useState<string[]>([]);
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const [loading, setLoading] = useState<boolean>(true);

  const fetchPermissions = useCallback(() => {
    if (isAuthenticated) {
      setLoading(true);
      // // TODO: Change this back to real permissions afterwards
      // const permissionsMock = [
      //   Permission.BasicAccess,
      //   Permission.ManageOwnPermissions,
      //   Permission.Tester,
      //   Permission.TestingTool,
      // ];
      // const featuresMock = [Features.DashboardScreen, Features.TestingToolScreen];
      // setPermissions(permissionsMock);
      // setFeatures(featuresMock);

      const { promise, abort } = permissionsService.getAuthorization();

      promise
        .then(authorization => {
          const { permissions, features, countries } = authorization;
          console.warn('---------------------- PERMISSIONS CONFIGURATION ----------------------');
          console.warn('permissions:', displayPermissionsGracefully(permissions));
          console.warn('features:', displayFeaturesGracefully(features));
          console.warn('countries:', displayFeaturesGracefully(countries));
          console.warn('-----------------------------------------------------------------------');
          setCountries(countries);
          setPermissions(permissions);
          setFeatures(features);
        })
        .catch(err => {
          console.error(err);
          setPermissions([]);
          setFeatures([]);
        })
        .finally(() => {
          setLoading(false);
        });

      return abort;
    } else {
      setPermissions([]);
      setFeatures([]);
      // setLoading(false);
    }
  }, [isAuthenticated]);

  useEffect(() => {
    const abortFn = fetchPermissions();

    return () => {
      abortFn?.();
    };
  }, [fetchPermissions]);

  const updatePermissions: FeaturesCtxType['updatePermissions'] = useCallback(
    async (permissions, countries) => {
      try {
        setUpdatePermissionsStatus(makeExternalDataInitialData);
        await permissionsService.update(permissions, countries);
        fetchPermissions();
        setUpdatePermissionsStatus(makeExternalCallSuccessData);
      } catch (err: any) {
        setUpdatePermissionsStatus(makeExternalCallErrorData(err));
        // errorHandler(err);
      }
    },
    [fetchPermissions],
  );

  const hasFeature = useCallback(
    (feature: Features): boolean => {
      return !!features.find(userFeature => userFeature === feature);
    },
    [features],
  );

  const fetchClaimsPermissions: FeaturesCtxType['fetchClaimsPermissions'] = useCallback(async () => {
    try {
      setClaimsPermissions(makeExternalDataInitialData);
      const claimsPermissions = await permissionsService.listClaimsPermissions();
      setClaimsPermissions(makeExternalDataSuccessData(claimsPermissions));
    } catch (err: any) {
      setClaimsPermissions(makeExternalCallErrorData(err));
      // errorHandler(err);
    }
  }, []);

  const fetchMarketLdapGroup = useCallback(async () => {
    const { promise, abort } = permissionsService.getMarketLdapGroup();

    setMarketLdapGroups([]);
    const res = await promise;
    setMarketLdapGroups(res);

    return abort;
  }, []);

  const fetchFeaturesMap: FeaturesCtxType['fetchFeaturesMap'] = useCallback(async () => {
    try {
      setFeaturesMap(makeExternalDataInitialData);
      const featuresMap = await permissionsService.getFeaturesMap();
      setFeaturesMap(makeExternalDataSuccessData(featuresMap));
    } catch (err: any) {
      setFeaturesMap(makeExternalCallErrorData(err));
      // errorHandler(err);
    }
  }, []);

  return (
    <PermissionsContext.Provider
      value={{
        loading,
        permissions,
        countries,
        marketLdapGroups,
        updatePermissionsStatus,
        featuresMap,
        claimsPermissions,
        updatePermissions,
        hasFeature,
        fetchClaimsPermissions,
        fetchFeaturesMap,
        fetchMarketLdapGroup,
      }}>
      {children}
    </PermissionsContext.Provider>
  );
};

export default FeaturesProvider;
