import SideWrapper from '../../../../../components/ui/side-wrapper';
import { Note } from '../../../../../resources/notes/notes-types';
import NoteForm from '../note-form';

interface Props {
  isToShow: boolean;
  close: () => void;
  note: Note;
}

const EditNoteTab: React.FC<Props> = ({ isToShow, close, note }) => {
  return (
    <SideWrapper title="Edit note" isToShow={isToShow} close={close} style={{ position: 'absolute', top: '-3px' }}>
      <NoteForm isToAdd={false} defaultValue={note} close={close} />
    </SideWrapper>
  );
};

export default EditNoteTab;
