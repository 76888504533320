import React from 'react';
import { Form, Modal } from 'react-bootstrap';
import DropdownBtn, { Item } from '../../../ui/dropdownBtn';
import { sortOptions } from '../../utils';

interface Props {
  showSortModal: boolean;
  setShowSortModal: React.Dispatch<React.SetStateAction<boolean>>;
  selectedSortOption: Item | undefined;
  setSelectedSortOption: React.Dispatch<React.SetStateAction<Item | undefined>>;
}

export default function SortOptionModal({
  showSortModal,
  setShowSortModal,
  selectedSortOption,
  setSelectedSortOption,
}: Props) {
  const handleCloseSortModal = () => setShowSortModal(false);

  return (
    <Modal show={showSortModal} onHide={handleCloseSortModal} dialogClassName="sort-modal">
      <Modal.Body>
        <Form>
          <DropdownBtn
            id="multi-select-dropdown-sort-options"
            none
            placeholder="Select Option"
            items={sortOptions}
            onClick={item => {
              setSelectedSortOption(item === null ? undefined : item);
            }}
            value={selectedSortOption}
          />
        </Form>
      </Modal.Body>
    </Modal>
  );
}
