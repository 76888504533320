export enum Permission {
  ManageOwnPermissions = 'ManageOwnPermissions',
  DashboardFullAccess = 'DashboardFullAccess',
  MaintenanceFullAccess = 'MaintenanceFullAccess',
  WebForecastFullAccess = 'WebForecastFullAccess',
  ScenarioFullAccess = 'ScenarioFullAccess',
  ReportsFullAccess = 'ReportsFullAccess',
  AdminFeatures = 'AdminFeatures',
  WebForecastReadOnly = 'WebForecastReadOnly',
  DashboardMarketAnalysis = 'DashboardMarketAnalysis',
  WebForecastMATOnly = 'WebForecastMATOnly',
  ScenarioMATOnly = 'ScenarioMATOnly',
  ReportsMarketSpecific = 'ReportsMarketSpecific',
}

export enum Features {
  ManageOwnPermissions = 'ManageOwnPermissions',
  AdminFeatures = 'AdminFeatures',
  DashboardFullAccess = 'DashboardFullAccess',
  DashboardScreen = 'DashboardScreen',
  DashboardMarketAnalysis = 'DashboardMarketAnalysis',
  MaintenanceFullAccess = 'MaintenanceFullAccess',
  MaintenanceScreen = 'MaintenanceScreen',
  WebForecastFullAccess = 'WebForecastFullAccess',
  WebForecastScreen = 'WebForecastScreen',
  WebForecastReadOnly = 'WebForecastReadOnly',
  WebForecastMATOnly = 'WebForecastMATOnly',
  ScenarioScreen = 'ScenarioScreen',
  ScenarioFullAccess = 'ScenarioFullAccess',
  ScenarioMATOnly = 'ScenarioMATOnly',
  ReportsScreen = 'ReportsScreen',
  ReportsFullAccess = 'ReportsFullAccess',
  ReportsMarketSpecific = 'ReportsMarketSpecific',
  AdminPanel = 'AdminPanel',
}
