import { useState } from 'react';
import { ExternalData } from '../../@types/external-api';
import {
  makeExternalCallErrorData,
  makeExternalDataInitialData,
  makeExternalDataSuccessData,
} from '../../helpers/external-data';
import DistCostForecastSettingsContext from './dist-cost-forecast-settings-context';
import { IDistCostForecastSettings } from './dist-cost-forecast-settings-types';
import distCostForecastSettingsService from './dist-cost-forecast-settings.service';

const DistCostForecastSettingsProvider: React.FC = ({ children }) => {
  const [distCostForecastSettings, setDistCostForecastSettings] = useState<ExternalData<IDistCostForecastSettings>>(
    makeExternalDataInitialData(),
  );

  const fetchDistCostForecastSettings = async (countryCode: string) => {
    setDistCostForecastSettings(makeExternalDataInitialData());

    const { promise, abort } = distCostForecastSettingsService.fetchDistCostForecastSettings(countryCode);

    try {
      const res = await promise;
      setDistCostForecastSettings(makeExternalDataSuccessData(res));
    } catch (e: any) {
      setDistCostForecastSettings(makeExternalCallErrorData(e));
    }

    return abort;
  };

  return (
    <DistCostForecastSettingsContext.Provider
      value={{
        fetchDistCostForecastSettings,
        distCostForecastSettings,
      }}>
      {children}
    </DistCostForecastSettingsContext.Provider>
  );
};

export default DistCostForecastSettingsProvider;
