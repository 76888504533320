import axios, { AxiosResponse } from 'axios';
import { WithAbortFn } from '../../@types/external-api';
import { apiWrapper } from '../../config/api-wrapper';
import { GetRankingReportRequest } from './ranking-report-types';

class RankingReportService {
  public downloadRankingReport(request: GetRankingReportRequest): WithAbortFn<Promise<AxiosResponse<any, any>>> {
    const source = axios.CancelToken.source();

    return {
      promise: apiWrapper.api.post('/ranking-report/download', request, { responseType: 'blob' }),
      abort: source.cancel,
    };
  }

  public sendToEmailRankingReport(request: GetRankingReportRequest): WithAbortFn<Promise<AxiosResponse>> {
    const source = axios.CancelToken.source();

    return {
      promise: apiWrapper.api.post('/ranking-report/email', request),
      abort: source.cancel,
    };
  }
}

export default new RankingReportService();
