import { MutableRefObject, useEffect, useRef, useState } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { AiOutlineFormatPainter } from 'react-icons/ai';
import { FaRegSave } from 'react-icons/fa';
import { HiOutlineDotsVertical } from 'react-icons/hi';
import { LuBookMarked, LuPencil } from 'react-icons/lu';
import { VscRepoClone } from 'react-icons/vsc';
import { useReactToPrint } from 'react-to-print';
import { ConfirmGeneralAction, NormalAlert } from '../../../components/core/alert-card/alert-cards';
import useMacroGraph from '../../../resources/macro-graph/macro-graph-hook';
import macroGraphService from '../../../resources/macro-graph/macro-graph.service';
import { exportToExcel } from '../utils';
import CreateSnapshotModal from './create-snapshot-modal';
import './index.scss';

interface Props {
  targetRef: MutableRefObject<HTMLDivElement | null>;
  shouldShowTabs: { [key: string]: boolean };
  setShouldShowTabs: React.Dispatch<React.SetStateAction<{ [key: string]: boolean }>>;
}

const Toolbar: React.FC<Props> = ({ targetRef, shouldShowTabs, setShouldShowTabs }) => {
  const [showSubmenuOptions, setShowSubmenuOptions] = useState(false);
  const { graphData, chartRealTimeData, chartStyle, chartDataIsDirty, setChartDataIsDirty } = useMacroGraph();
  const submenuRef = useRef<HTMLDivElement>(null);
  const [isSavingChanges, setIsSavingChanges] = useState(false);
  const [openCreateSnapshotModal, setOpenCreateSnapshotModal] = useState(false);

  const handlePrint = useReactToPrint({
    content: () => targetRef.current,
    documentTitle: `macro_forecast_${new Date().toISOString()}.pdf`,
  });

  const changeShowTab = (tabId: string) => {
    const currentValue = shouldShowTabs[tabId];
    const updatedIsToShowTabs: { [key: string]: boolean } = {};

    if (currentValue) {
      updatedIsToShowTabs[tabId] = false;
    } else {
      for (const k in shouldShowTabs) {
        updatedIsToShowTabs[k] = false;
      }
      updatedIsToShowTabs[tabId] = true;
    }

    setShouldShowTabs(updatedIsToShowTabs);
  };

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (submenuRef.current && !submenuRef.current.contains(event.target as Node)) {
        setShowSubmenuOptions(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleSaveChanges = () => {
    ConfirmGeneralAction(
      'Do you really want to save the changes?',
      'Changes saved successfully',
      'Error saving changes, please try again.',
      saveChanges,
    );
  };

  const saveChanges = async () => {
    setIsSavingChanges(true);
    await macroGraphService.persistValues();
    setIsSavingChanges(false);
    setChartDataIsDirty(false);
  };

  return (
    <div className="toolbar">
      <div className="options">
        <OverlayTrigger placement="bottom" overlay={<Tooltip>Save changes</Tooltip>}>
          <button disabled={!chartDataIsDirty || isSavingChanges} onClick={handleSaveChanges}>
            <FaRegSave size={24} />
          </button>
        </OverlayTrigger>
        <OverlayTrigger placement="bottom" overlay={<Tooltip>Create Snapshot</Tooltip>}>
          <button onClick={() => setOpenCreateSnapshotModal(true)}>
            <VscRepoClone size={24} />
          </button>
        </OverlayTrigger>
        <OverlayTrigger placement="bottom" overlay={<Tooltip>Theme</Tooltip>}>
          <button
            onClick={() => {
              changeShowTab('theme');
              setShowSubmenuOptions(false);
            }}>
            <AiOutlineFormatPainter size={24} />
          </button>
        </OverlayTrigger>
        <OverlayTrigger placement="bottom" overlay={<Tooltip>Graph Lines</Tooltip>}>
          <button
            onClick={() => {
              changeShowTab('input-data');
              setShowSubmenuOptions(false);
            }}>
            <LuPencil size={24} />
          </button>
        </OverlayTrigger>
        <OverlayTrigger placement="bottom" overlay={<Tooltip>Notes</Tooltip>}>
          <button
            onClick={() => {
              changeShowTab('notes');
              setShowSubmenuOptions(false);
            }}>
            <LuBookMarked size={24} />
          </button>
        </OverlayTrigger>
        <OverlayTrigger placement="bottom" overlay={<Tooltip>More options</Tooltip>}>
          <button onClick={() => setShowSubmenuOptions(!showSubmenuOptions)}>
            <HiOutlineDotsVertical size={24} />
          </button>
        </OverlayTrigger>
      </div>

      {showSubmenuOptions ? (
        <div className="submenu" ref={submenuRef}>
          <button
            onClick={() => {
              if (graphData && graphData.data && graphData.data.graphs.length > 0) {
                exportToExcel(graphData.data, chartStyle);
              } else {
                NormalAlert('There is no data to export');
              }
              setShowSubmenuOptions(false);
            }}>
            Export as XLS
          </button>
          <button
            onClick={() => {
              if (graphData && graphData.data && graphData.data.graphs.length > 0) {
                handlePrint();
              } else {
                NormalAlert('There is no data to export');
              }
              setShowSubmenuOptions(false);
            }}>
            Export as PDF
          </button>
        </div>
      ) : (
        <></>
      )}
      <CreateSnapshotModal isOpen={openCreateSnapshotModal} close={() => setOpenCreateSnapshotModal(false)} />
    </div>
  );
};

export default Toolbar;
